import { Reducer, Store } from 'redux';
import { useEffectAsync } from '@local/hooks';
import { ENV } from '@local/constants';
import { resourceReducer } from './dynamic/reducersCreators';
import * as actions from './dynamic/actions';

interface Props extends Store {
	useReducer: (name: string, reducer: Reducer) => void;
}

const catchFunc = (e) => {
	console.error(e);
	return null;
};

// const API_ROOT = 'https://cms.tradinglite.com/';
// const API_ROOT = 'http://cms.chainview.us:1337/';

const get = (resource) =>
	fetch(`${ENV.API_ROOT}/${resource}`, { method: 'GET' })
		.then((response) => response.json())
		.catch(catchFunc);

const post = (resource) =>
	fetch(`${ENV.API_ROOT}/${resource}`, { method: 'POST' })
		.then((response) => response.json())
		.catch(catchFunc);

const SERVICES = {
	get,
	post,
};

const reducer = resourceReducer();

const getUseResource =
	({ useReducer, dispatch }: Props) =>
	(space: string, loader: (services: typeof SERVICES) => any): void => {
		useReducer(space, reducer);

		// Fetch data
		useEffectAsync(async () => {
			if (loader)
				try {
					const payload = await loader(SERVICES);
					dispatch(actions.loadSuccess(space, payload));
				} catch (e) {
					dispatch(actions.loadError(space, e));
				}
		}, [loader]);
	};

export default getUseResource;
