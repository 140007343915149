import { useAppStore, actions } from '@local/store';
import { SAVE_TARGETS } from 'pages/account_settings/SAVE_TARGETS';
import { api } from '@local/services/api';
import { COMMON_SPACES } from '@local/constants';
import SPACES from './SPACES';

const sessionsLoader = ({ apiGet }) => apiGet('SessionsList');
const toReject = () => Promise.reject();

const loader = ({ apiGet }) => apiGet('UserInfo');

const ref = ({ apiPost }) => apiPost('Ref');

const Actor = () => {
	const { useForm, dispatch, useStoreSpace } = useAppStore();
	const auth = useStoreSpace(COMMON_SPACES.AUTH);
	useForm({
		space: SPACES.REDIRECT,
		submitter: ({ apiPost }, value) => {
			if (value?.priceId) return apiPost('invoice', value);

			alert('No price to choose');
		},
		onSaved: (resp) => {
			window.open(resp.hostedInvoiceUrl, '_self');
		},
	});
	useForm({
		space: SPACES.SETTINGS,
		loader,
		submitter: async ({ apiPost, apiGet }, value, originalValue, target) => {
			switch (target) {
				case SAVE_TARGETS.EMAIL:
					return apiPost('ChangeEmail', {
						email: value.email,
					});
				case SAVE_TARGETS.NAME:
					return apiPost('ChangeUserName', { userName: value.userName });
				case SAVE_TARGETS.PASSWORD:
					return apiPost('ChangePassword', { password: value.password });
				case SAVE_TARGETS.AUTO_PLAN_RENEW: {
					return apiGet(`AutoPlanRenew?value=${value.autoPlanRenew}`);
				}
				default:
					throw Error('no target for save');
			}
		},
	});

	useForm({
		space: SPACES.REFERRAL_INFO,
		loader: auth?.success ? ref : toReject,
		submitter: async ({ apiPost, apiGet }, value) => {
			const ref = await apiPost('RefName', { name: value?.name });
			const referralLink = await apiGet(`refv?name=${value?.name}`);
			if (ref.success && referralLink.success) {
				const obj = {
					...ref.data,
					referralLink: referralLink.data,
				};
				dispatch(actions.set(SPACES.REFERRAL_INFO, obj));
			}
		},
	});

	useForm({
		space: SPACES.INFO,
		loader: auth?.success ? loader : toReject,
	});

	useForm({
		space: SPACES.SESSIONS,
		loader: sessionsLoader,
		submitter: async ({ apiGet }, value, _, target) => {
			if (typeof target === 'boolean') {
				await Promise.allSettled(value.map(({ sessionId }) => apiGet(`RemoveSession?id=${sessionId}`)));
				dispatch(actions.set(COMMON_SPACES.AUTH, { success: false }));
			} else {
				await apiGet(`RemoveSession?id=${target}`);
				const filteredList = value.filter(({ sessionId }) => sessionId !== target);
				dispatch(actions.set(SPACES.SESSIONS, filteredList));
				const result = await api.apiGet('Ping');
				if (!result?.success) dispatch(actions.set(COMMON_SPACES.AUTH, { success: false }));
			}
		},
	});
	return null;
};

export default Actor;
