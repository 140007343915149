import React, { memo } from 'react';
import { Button } from '@local/components';
import { ROUTE_PATH_KEYS, LINKS, BUTTON_VIEW, COMMON_SPACES, EMPTY } from '@local/constants';
import { useAppStore, routeActions } from '@local/store';
import { TTag } from '@local/dataTypes';
import SPACES from './SPACES';

const Tags: React.FC = memo(() => {
	const { useStoreSpace, useAction } = useAppStore();
	const objTags: [TTag] = useStoreSpace(SPACES.TAGS);
	const selectedTag = Number(useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.TAG)) || 0;
	const onChange = useAction(({ target: { value } }) => routeActions.push(LINKS.LEARN, { tag: value }));
	return (
		<div className="button-set">
			<Button view={BUTTON_VIEW.BOX_GREEN} activeValue={0} value={selectedTag} onChange={onChange}>
				ALL
			</Button>
			{(objTags ?? EMPTY.ARRAY).map(({ id, tag }) => (
				<Button view={BUTTON_VIEW.BOX_GREEN} activeValue={id} value={selectedTag} key={id} onChange={onChange}>
					{tag.toUpperCase()}
				</Button>
			))}
		</div>
	);
});

Tags.displayName = 'Tags';

export default Tags;
