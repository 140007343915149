import React from 'react';
import './style.scss';
import classNames from 'classnames';
import borderGreen from '../../../images/borderGreen.svg';
import borderGreenLarge from '../../../images/borderGreenLarge.svg';
import borderYellow from '../../../images/borderYellow.svg';
import borderGrey from '../../../images/borderGrey.svg';
import { InterectiveProps } from '../types';

interface ButtonAttrs<TValue> extends InterectiveProps<TValue> {
	yellow?: TValue;
	green?: TValue;
	large?: TValue;
	disabled?: boolean;
}

const BorderButton = <TValue,>(props: ButtonAttrs<TValue>): JSX.Element => {
	const { children, yellow, green, large = false, disabled = false, onChange } = props;
	return (
		<div
			aria-hidden="true"
			className={classNames('border-button', {
				'border-button-yellow': yellow,
				'border-button-green': green,
				'border-button-large': large,
				'border-button-disabled': disabled,
			})}
			onClick={onChange as () => void}
		>
			<img
				src={
					!green && !yellow
						? borderGrey
						: yellow
						? borderYellow
						: green
						? large
							? borderGreenLarge
							: borderGreen
						: borderGrey
				}
				alt=""
			/>
			<div className="border-button-children">{children}</div>
		</div>
	);
};

export default BorderButton;
