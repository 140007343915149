const PAGE_NAMES = {
	HOME: 'home',
	NEWS: 'news',
	LEARN: 'learn',
	PRICING: 'pricing',
	RESET_PASSWORD: 'reset-password',
	VERIFICATION_CODE: 'verification-code',
	CHANGE_PASSWORD: 'change-password',
	SIGN_IN: 'sign-in',
	LEARN_MORE: 'learn-more',
	REGISTER: 'register',
	/* LISA_SCRIPT: 'lisa-script', */
	ON_CHAIN: 'on-chain',
	CHART: 'chart',
	ACCOUNT_SETTINGS: 'account-settings',
	ACCOUNT_SETTINGS_UPGRADE: 'account-settings/upgrade',
	ABOUT: 'about',
};

export default PAGE_NAMES;
