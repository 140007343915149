import { actions, useAppStore } from '@local/store';
import { Button } from '@local/components';
import React from 'react';

interface Props {
	space: string;
	view?: string;
}

export const SubmitContainer: React.FC<Props> = ({ space, children, view }) => {
	const { useAction } = useAppStore();

	return (
		<Button view={view} activeValue={'save'} onChange={useAction(() => actions.submit(space))}>
			{children}
		</Button>
	);
};
