export const PLANS = {
	FREE: 0,
	PRO: 1,
	'PRO+': 2,
	PREMIUM: 3,
};

export const PLAN_DATA = {
	mounthly: {
		[PLANS.PRO]: 'price_1KLtXwFykZxtREr9B6sCSOh5',
		2: 'price_1KLtaLFykZxtREr9x7WQSgIx',
		[PLANS.PREMIUM]: 'price_1KLtfsFykZxtREr9Ea96IdQq',
	},
	annually: {
		[PLANS.PRO]: 'price_1KLtXwFykZxtREr9LJoepJ5p',
		2: 'price_1KLtaLFykZxtREr9kF8kh7qr',
		[PLANS.PREMIUM]: 'price_1KLtfsFykZxtREr9Ea96IdQq',
	},
};

export const PLAN_DATA_TEST = {
	mounthly: {
		[PLANS.PRO]: 'price_1KLtXwFykZxtREr9B6sCSOh5',
		2: 'price_1KLtaLFykZxtREr9x7WQSgIx',
		[PLANS.PREMIUM]: 'price_1KLtfsFykZxtREr9Ea96IdQq',
	},
	annually: {
		[PLANS.PRO]: 'price_1KLtXwFykZxtREr9LJoepJ5p',
		2: 'price_1KLtaLFykZxtREr9kF8kh7qr',
		[PLANS.PREMIUM]: 'price_1KLtfsFykZxtREr9Ea96IdQq',
	},
};
