import React from 'react';
import 'pages/common/style.scss';
import { BorderButton } from '@local/components';
import { routeActions, useAppStore } from '@local/store';
import { LINKS, PLANS, PLAN_DATA_TEST } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';

interface Props {
	title: string;
	active: boolean;
	current: boolean;
	detailsLink: boolean;
	description: string;
	price?: string;
	priceYear?: string;
	period?: string;
	year?: boolean;
	disabled?: boolean;
	onSelect?: (v: any) => void;
}

const PriceCard: React.FC<Props> = ({
	active,
	current,
	detailsLink,
	onSelect,
	title,
	description,
	price,
	priceYear,
	period,
	year,
	disabled,
}) => {
	const { useAction } = useAppStore();

	const cardPriceId = period && PLAN_DATA_TEST[period][PLANS[title]];

	const titlePeriod = (periodValue) =>
		period === periodValue
			? priceYear
				? `$${priceYear}/year`
				: ''
			: price
			? year
				? `$${price}/year`
				: `$${price}/month`
			: '';

	const onSelectHandler = () => (onSelect && !disabled && !current ? onSelect(cardPriceId) : null);

	return (
		<div className={(!current && active) || current ? 'price-card-active price-card' : 'price-card'}>
			<div className="price-card__title">{title}</div>
			<div className="price-card__description">{description}</div>
			<div className="price-card__price">{titlePeriod('annually')}</div>
			<div className="price-card__year">{!year && titlePeriod('mounthly')}</div>
			<div className={'price-card__btn'}>
				{current ? (
					<BorderButton yellow disabled={disabled || current} onChange={onSelectHandler}>
						CURRENT
					</BorderButton>
				) : active ? (
					!disabled && active ? (
						<BorderButton disabled={disabled || current} yellow={true} onChange={onSelectHandler}>
							CHOOSE
						</BorderButton>
					) : (
						<BorderButton disabled={disabled || current} onChange={onSelectHandler}>
							CHOOSE
						</BorderButton>
					)
				) : (
					<BorderButton disabled={disabled || current} green={!disabled} onChange={onSelectHandler}>
						CHOOSE
					</BorderButton>
				)}
			</div>
			{detailsLink && (
				<button
					className="price-card__details"
					onClick={useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.PRICING }))}
				>
					Details
				</button>
			)}
		</div>
	);
};

export default PriceCard;
