import { useAppStore } from '@local/store';
import { sortByDate } from '@local/services';
import SPACES from './SPACES';

const loader = async ({ get }) => sortByDate(await get('blogs'));

const Actor = () => {
	const { useResource } = useAppStore();
	useResource(SPACES.LIST, loader);
	return null;
};

export default Actor;
