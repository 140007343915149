import React from 'react';
import { LINKS } from '@local/constants';
import { TopMenu, ArticlesList, Footer, Pagination } from 'pages/common';
import { NewsArticle } from 'pages/news/NewsArticle';
import SPACES from './SPACES';

import './style.scss';

const layout = () => (
	<div className="news-container">
		<TopMenu />
		<NewsArticle space={SPACES.LIST} />
		<div className="news-container__table">
			<ArticlesList space={SPACES.LIST} withoutFirst />
			<Pagination link={LINKS.NEWS} space={SPACES.LIST} />
		</div>
		<Footer />
	</div>
);

export default layout;
