import React, { useState } from 'react';
import { Footer, PriceCard, TopMenu } from 'pages/common';
import { BorderButton, TitlePage } from '@local/components';
import { routeActions, useAppStore } from '@local/store';
import { COMMON_SPACES, LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { SUB_PAGES } from 'pages/account_settings/SUB_PAGES';

const Th = (title, active = false) => {
	return <th className={active ? 'th-active' : ''}>{title}</th>;
};

const Td = (title, active = false) => {
	const className = active ? 'td-active' : '';
	if (title === 'clear') return <td className={className}>&#10006;</td>;
	if (title === 'success') return <td className={className}>&#10004;</td>;
	return <td className={className}>{title}</td>;
};

const Form: React.VFC = () => {
	const { useStoreSpace, useAction } = useAppStore();
	const [period, setPeriod] = useState('annually');
	const auth = useStoreSpace(COMMON_SPACES.AUTH);

	const onSelect = useAction(() =>
		auth?.success
			? routeActions.push(LINKS.ACCOUNT_SETTINGS, { subPage: SUB_PAGES.UPGRADE })
			: routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN })
	);

	return (
		<div className="pricing-page">
			<TopMenu />
			<div className="pricing-page__first-section">
				<TitlePage>Plans</TitlePage>
				<div className="pricing-page__btnPeriod">
					<BorderButton yellow={period === 'mounthly'} onChange={() => setPeriod('mounthly')}>
						Monthly
					</BorderButton>
					<BorderButton yellow={period === 'annually'} onChange={() => setPeriod('annually')}>
						Annually
					</BorderButton>
				</div>
				<div style={{ paddingBottom: 0 }} className="pricing-page__priceContainer">
					<PriceCard
						active={false}
						// disabled={true}
						current={false}
						detailsLink={false}
						title="FREE"
						description="Basic features."
						onSelect={onSelect}
						period={period}
					/>
					<PriceCard
						active={false}
						// disabled={info?.value?.plan === PLANS['PRO+'] || info?.value?.plan === PLANS.PREMIUM}
						current={false}
						detailsLink={false}
						title="PRO"
						description="Advanced features."
						price="15"
						priceYear="150"
						onSelect={onSelect}
						period={period}
					/>
					<PriceCard
						active={true}
						// disabled={info?.value?.plan === PLANS.PREMIUM}
						current={false}
						detailsLink={false}
						title="PRO+"
						description="All the main features, for professional traders."
						price="25"
						priceYear="250"
						onSelect={onSelect}
						period={period}
					/>
					<PriceCard
						active={false}
						current={false}
						detailsLink={false}
						title="PREMIUM"
						description="For users who love the highest quality."
						price="4900"
						year={true}
						priceYear="4900"
						onSelect={onSelect}
						period={period}
					/>
				</div>
			</div>

			<div className="pricing-page__table">
				<table>
					<tr>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th />
						{Th('FREE')}
						{Th('PRO')}
						{Th('PRO+', true)}
						{Th('PREMIUM')}
					</tr>
					<tr>
						<td>Connected Devices</td>
						{Td('1')}
						{Td('2')}
						{Td('3', true)}
						{Td('4')}
					</tr>
					<tr>
						<td>Multiple Sessions</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>

					<div className="divider" />

					<tr>
						<td>Advanced Orderbook</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Trades Ticker</td>
						{Td('success')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Drawing Tools(BETA)</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Cloud Synchronization</td>
						{Td('clear')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Theme Customization</td>
						{Td('clear')}
						{Td('clear')}
						{Td('success', true)}
						{Td('success')}
					</tr>

					<div className="divider" />

					<tr>
						<td>Default Timeframes (5m, 15m, 30m, 1h)</td>
						{Td('success')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Lower Timeframe (1m)</td>
						{Td('success')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Higher Timeframes (2h, 4h, 6h, 8h, 12h, D)</td>
						{Td('success')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>

					<div className="divider" />

					<tr>
						<td>Heatmap</td>
						{Td('clear')}
						{Td('HD/FULLHD')}
						{Td('4K', true)}
						{Td('8K')}
					</tr>
					<tr>
						<td>Volume and Heatmap Lens</td>
						{Td('clear')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Realtime data flow (no delay)</td>
						{Td('success')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Full Historical Data</td>
						{Td('clear')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Heiken Ashi, Line charts</td>
						{Td('clear')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Open Interest, Liquidations(BUY, SELL, TOT), Funding rate, Mark Price</td>
						{Td('success')}
						{Td('success')}
						{Td('success', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Exchanges</td>
						{Td('All')}
						{Td('All')}
						{Td('All', true)}
						{Td('All')}
					</tr>

					<div className="divider" />

					<tr>
						<td>Footprints</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Footprints for more than 15000 pairs*timeframes</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Bid-ask cluster</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Funding rate panel</td>
						{Td('clear')}
						{Td('clear')}
						{Td('success', true)}
						{Td('success')}
					</tr>

					<div className="divider" />

					<tr>
						<td>On Chain Data (on.chainview.us)</td>
						{Td('')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Inflow Data</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>Outflow Data</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>
					<tr>
						<td>On Chain Metrics</td>
						{Td('clear')}
						{Td('clear')}
						{Td('clear', true)}
						{Td('success')}
					</tr>

					<div className="divider" />

					<tr>
						<td>Access to latest upcomming features</td>
						{Td('clear')}
						{Td('clear')}
						{Td('success', true)}
						{Td('success')}
					</tr>
				</table>
			</div>

			<Footer />
		</div>
	);
};

export default Form;
