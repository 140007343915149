import React, { useState } from 'react';
import { PriceCard } from 'pages/common';
import { useAppStore, actions } from '@local/store';
import { COMMON_SPACES, PLANS } from '@local/constants';
import { BorderButton, Loader } from '@local/components';
import SPACES from '../SPACES';

import './style.scss';

const Upgrade: React.FC = () => {
	const { useStoreSpace, dispatch } = useAppStore();
	const store = useStoreSpace(SPACES.REDIRECT);
	const auth = useStoreSpace(COMMON_SPACES.AUTH);
	const info = useStoreSpace(SPACES.SETTINGS);
	const [period, setPeriod] = useState('annually');

	const onSelect = (priceId) => {
		dispatch(actions.setPath(SPACES.REDIRECT, ['priceId'], priceId));
		dispatch(actions.submit(SPACES.REDIRECT));
	};

	return (
		<div className="account-setting-upgrade">
			<div className="account-setting-upgrade__title">Select a Plan</div>
			<div className="account-setting-upgrade__btnPeriod">
				<BorderButton yellow={period === 'mounthly'} onChange={() => setPeriod('mounthly')}>
					Monthly
				</BorderButton>
				<BorderButton yellow={period === 'annually'} onChange={() => setPeriod('annually')}>
					Annually
				</BorderButton>
			</div>
			{store?.submitting && <Loader />}
			<div style={{ paddingBottom: 0 }} className="account-setting-upgrade__priceContainer">
				<PriceCard
					active={false}
					disabled={true}
					current={PLANS.FREE === info?.value?.plan}
					detailsLink={true}
					title="FREE"
					period={period}
					onSelect={onSelect}
					description="Basic features."
				/>
				<PriceCard
					active={false}
					disabled={info?.value?.plan === PLANS['PRO+'] || info?.value?.plan === PLANS.PREMIUM}
					current={PLANS.PRO === info?.value?.plan}
					detailsLink={true}
					title="PRO"
					period={period}
					description="Advanced features."
					price="15"
					onSelect={onSelect}
					priceYear="150"
				/>
				<PriceCard
					active={!auth?.success}
					disabled={info?.value?.plan === PLANS.PREMIUM}
					current={PLANS['PRO+'] === info?.value?.plan}
					detailsLink={true}
					title="PRO+"
					description="All the main features, for professional traders."
					period={period}
					onSelect={onSelect}
					price="25"
					priceYear="250"
				/>
				<PriceCard
					active={false}
					current={PLANS.PREMIUM === info?.value?.plan}
					detailsLink={true}
					period={period}
					title="PREMIUM"
					onSelect={onSelect}
					description="For users who love the highest quality."
					price="4900"
					year={true}
					priceYear="4900"
				/>
			</div>
		</div>
	);
};

Upgrade.displayName = 'Upgrade';

export default Upgrade;
