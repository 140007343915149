import { routeActions, useAppStore } from '@local/store';
import * as yup from 'yup';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import SPACES from './SPACES';

const schema = yup.object().shape({
	code: yup.string().required(),
});

const Actor = () => {
	const { useForm, dispatch } = useAppStore();
	useForm({
		space: SPACES.FORM,
		schema,
		submitter: async ({ apiPost }, value) => apiPost('CodeReset', { code: Number(value.code) }),
		onSaved: (resp) => {
			localStorage.setItem('v', JSON.stringify(resp ?? null));
			dispatch(routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.CHANGE_PASSWORD }));
		},
	});
	return null;
};

export default Actor;
