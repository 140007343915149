import React from 'react';
import { routeActions, useAppStore } from '@local/store';
import { BUTTON_VIEW, COMMON_SPACES, LINKS, ROUTE_PATH_KEYS } from '@local/constants';
import { Button, Swiper } from '@local/components';
import SUB_PAGE_NAMES from 'pages/learn/SUB_PAGE_NAMES';

export const SubPagesButtons: React.VFC = () => {
	const { useStoreSpace, useAction } = useAppStore();
	const subpage = useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.SUBPAGE);
	const onChange = useAction(({ target: { value } }) => routeActions.push(LINKS.LEARN, { subPage: value }));
	const buttonProps = { value: subpage, onChange };
	return (
		<Swiper>
			<Button {...buttonProps} activeValue={SUB_PAGE_NAMES.MARKET} view={BUTTON_VIEW.SMALL_ICON}>
				MARKET DATA
			</Button>
			<Button {...buttonProps} activeValue={SUB_PAGE_NAMES.ON_CHAIN} view={BUTTON_VIEW.SMALL_ICON}>
				ON CHAIN
			</Button>
		</Swiper>
	);
};
