import React from 'react';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import 'pages/common/style.scss';
import { ENV } from '@local/constants';
import { InterectiveProps } from '@local/components';
import { ArticleItem } from '@local/dataTypes';

const DATE_FORMAT = 'MMM d';
export const formatDate = (date?: string): string => {
	const parsedDate = date && parseISO(date);
	return parsedDate ? format(parsedDate, DATE_FORMAT) : '-';
};

interface Props extends InterectiveProps<ArticleItem> {
	bgInfo: boolean;
}

const ArticleListItem: React.FC<Props> = ({ value, name, bgInfo, onChange }) => {
	return (
		<div
			className="article-list__item"
			aria-hidden="true"
			onClick={() => onChange && onChange({ target: { name, value } })}
		>
			<img className="img" src={`${ENV.API_ROOT}${value?.thumb?.[0]?.formats?.thumbnail?.url}`} alt="img" />
			<div
				className={classNames({
					'article-list__item-bg': bgInfo,
				})}
			>
				<div className="article-list__item--title">{value?.title}</div>
				{!bgInfo && <div className="article-list__item--description">{value?.description}</div>}
				<div className="article-list__item--user-info">
					<img
						className="avatar"
						src={`${ENV.API_ROOT}${value?.author?.pic?.[0]?.formats?.thumbnail?.url}`}
						alt="avatar"
					/>
					<div className="description">
						<div>{value?.author?.name}</div>
						<div className="date">{formatDate(value?.created_at)}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArticleListItem;
