import React, { useCallback, useEffect, useState } from 'react';
import SPACES from 'pages/signin/SPACES';
import { InputContainer, SubmitContainer } from '@local/containers';
import { BorderButton, Button, Icon, Loader } from '@local/components';
import { LINKS, BUTTON_VIEW } from '@local/constants';
import { routeActions, useAppStore } from '@local/store';
import { VectorForms, ServerErrors } from 'pages/common';
import PAGE_NAMES from '../PAGE_NAMES';
import VECTOR from '../../images/VECTOR.png';
import eye from '../../images/eye.svg';

const Form: React.FC = () => {
	const { useAction, useStoreSpace } = useAppStore();
	const store = useStoreSpace(SPACES.FORM);

	useEffect(() => {
		localStorage.removeItem('v');
	}, []);

	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');

	const onCreate = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.REGISTER }));
	const onClose = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.HOME }));
	const onForgot = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.RESET_PASSWORD }));
	const onTogglePasswordEye = useCallback(() => {
		setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));
	}, [setPasswordType]);

	return (
		<section className="sign-in__container">
			<div className="sign-in__content">
				<VectorForms />
				<img className="vector" src={VECTOR} alt="" />
				<Icon icon={Icon.icons.CLOSE} title="close" onClick={onClose} className="close" />

				<div className="title">SIGN IN</div>
				<div className="foreword">To sign in enter you details below</div>

				<InputContainer label="Username" space={SPACES.FORM} name="userName" type="email" />
				<InputContainer
					label="Password"
					space={SPACES.FORM}
					name="password"
					type={passwordType}
					icons={
						<div aria-hidden="true" className={`eye-${passwordType}`} onClick={onTogglePasswordEye}>
							<img src={eye} alt="" />
						</div>
					}
				/>

				{!store?.submitting && <ServerErrors space={SPACES.FORM} />}

				<Button className="forgot-password" onChange={onForgot}>
					Forgot password?
				</Button>

				{store?.submitting && <Loader />}

				<SubmitContainer space={SPACES.FORM}>
					<BorderButton large green>
						SIGN IN
					</BorderButton>
				</SubmitContainer>
				<div className="create-account">
					Don’t have an account?
					<Button onChange={onCreate} view={BUTTON_VIEW.LINK}>
						Create account
					</Button>
				</div>
			</div>
		</section>
	);
};

export default Form;
