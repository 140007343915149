import React from 'react';
import { useAppStore } from '@local/store';
import { ENV } from '@local/constants';
import ReactMarkdown from 'react-markdown';
import { ArticleFull } from '@local/dataTypes';
import { time } from '../../utils';
import ArticlesList from './ArticlesList';

interface Props {
	space: string;
	otherArticlesSpace: string;
}

const transformImageUri = (src) => `${ENV.API_ROOT}${src}`;
const onScrollTop = () =>
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});

const Article: React.FC<Props> = ({ space, otherArticlesSpace }) => {
	const { useStoreSpace } = useAppStore();
	const value = useStoreSpace(space)?.value as ArticleFull;

	return (
		<div className="article-container">
			<div className="article-container__blockchain">BLOCKCHAIN</div>
			<div className="article-container__title">{value?.title}</div>
			<div className="article-container__author">
				<div className="article-container__imgAuthor">
					<img src={`${ENV.API_ROOT}${value?.author?.pic?.[0]?.formats?.thumbnail?.url}`} alt="author" />
					<div className="author-title">{value?.author?.name}</div>
				</div>

				<div className="date-container">
					<div className="date">{time.formatDate(value?.created_at)}</div>
					<div className="date">10 min read</div>
				</div>
			</div>

			<div className="article-container__first-section">
				<div className="article-container__first-section--content">
					<div className="left">
						<ReactMarkdown transformImageUri={transformImageUri}>{value?.content}</ReactMarkdown>

						<div className="btn-container">
							{value?.tags?.map(({ id, tag }) => (
								<button className="btn" key={id}>
									{tag?.toUpperCase()}
								</button>
							))}
						</div>
					</div>

					<div className="right">
						<div className="title">Other articles</div>
						<ArticlesList space={otherArticlesSpace} pageLimit={4} bgInfo />
					</div>
				</div>
				<div className="article-container__divider" />
				<div className="article-container__content">
					<div className="left">
						<div className="up-text">
							<button onClick={onScrollTop}>UP</button>
							<button onClick={onScrollTop}>NEXT</button>
						</div>
						<div className="up-description">{value?.description}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Article;
