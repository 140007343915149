export const CHANGE_FILTER = 'CHANGE_FILTER';
export const SET_FILTER = 'SET_FILTER';
export const CHANGE_SORT = 'CHANGE_SORT';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const SET_NEED_RELOAD = 'SET_NEED_RELOAD';
export const START_LOAD = 'START_LOAD';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_ERROR = 'LOAD_ERROR';
export const SUBMIT = 'SUBMIT';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_ERROR = 'SUBMIT_ERROR';
export const START_RELOAD = 'START_RELOAD';
export const LOCAL_SORT = 'LOCAL_SORT';
export const INVALIDATE = 'INVALIDATE';
export const SET = 'SET';
export const SET_PATH = 'SET_PATH';
export const MERGE = 'MERGE';
export const RESET = 'RESET';
export const VALIDATION_ERRORS = 'VALIDATION_ERRORS';
export const DEL = 'DEL';
export const SET_MAIN = 'SET_MAIN';
export const SELECT = 'SELECT';
export const PUSH = 'PUSH';
export const SHIFT = 'SHIFT';
