import { createContext } from 'react';
import { getUseStorePath } from 'use-store-path';
import { createStore, applyMiddleware, compose, Reducer, StoreEnhancer } from 'redux';
import history from 'route/history';
import { reducer as createDynamicReducer } from '@simprl/dynamic-reducer';
import getUseAction from './useAction';
import routerMiddleware from './route/routerMiddleware';
import getUseReducer from './useReducer';
import getUseResource from './useResource';
import getUseForm from './useForm';

const { reducer: dynamicReducer, addReducer } = createDynamicReducer();

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__ as typeof compose;

const middlewares = reduxDevTools
	? (compose(applyMiddleware(routerMiddleware(history)), reduxDevTools()) as StoreEnhancer)
	: applyMiddleware(routerMiddleware(history));

const store = createStore(dynamicReducer as Reducer, {}, middlewares);
const useStorePath = getUseStorePath(store);
const useStoreSpace = (...path: string[]): any => useStorePath(path);
const useReducer = getUseReducer({ ...store, addReducer });
const useResource = getUseResource({ ...store, useReducer });
const useForm = getUseForm({ ...store, useReducer, useStoreSpace });
export const extandedStore = {
	...store,
	history,
	useReducer,
	useResource,
	useForm,
	useAction: getUseAction(store),
	useStoreSpace,
	// getSpaceState: (space: string): any => store.getState()?.[space],
};

export type AppStoreType = typeof extandedStore;

const StoreContext = createContext<AppStoreType>(extandedStore);

export default StoreContext;
