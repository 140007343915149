import './style.scss';
import { Footer, TopMenu } from 'pages/common';
import Form from './Form';

const layout = () => {
	return (
		<>
			<Form />
		</>
	);
};

export default layout;
