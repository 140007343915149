import { actions, useAppStore } from '@local/store';
import ReactCodeInput from 'react-code-input';
import React, { VFC } from 'react';

interface Props {
	space: string;
}

export const CodeContainer: VFC<Props> = ({ space }) => {
	const { useStoreSpace, useAction } = useAppStore();

	const { value } = useStoreSpace(space) ?? {};

	return (
		<ReactCodeInput
			name="code"
			value={value?.code ?? ''}
			type="tel"
			fields={5}
			onChange={useAction((value) => actions.setPath(space, ['code'], value))}
			inputMode="tel"
		/>
	);
};
