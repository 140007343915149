import React, { useCallback, useState } from 'react';
import { Footer, ServerErrors } from 'pages/common';
import { BorderButton } from '@local/components';
import { InputContainer, SubmitContainer } from '@local/containers';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { useAppStore, routeActions } from '@local/store';
import arrowBack from 'images/arrow-back.svg';
import eye from '../../images/eye.svg';
import SPACES from './SPACES';

import './style.scss';

const Form: React.FC = () => {
	const { useAction } = useAppStore();

	const [passwordType1, setPasswordType1] = useState('password');
	const [passwordType2, setPasswordType2] = useState('password');
	const onTogglePasswordEye1 = useCallback(() => {
		setPasswordType1((prev) => (prev === 'password' ? 'text' : 'password'));
	}, [setPasswordType1]);
	const onTogglePasswordEye2 = useCallback(() => {
		setPasswordType2((prev) => (prev === 'password' ? 'text' : 'password'));
	}, [setPasswordType2]);

	const onBack = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }));
	return (
		<div className="change-password">
			<div className="change-password__container">
				<div className="change-password__content">
					<div aria-hidden="true" className="arrow-back" onClick={onBack}>
						<img src={arrowBack} alt="" />
					</div>
					<h1 className="title">CHANGE PASSWORD</h1>
					<InputContainer
						label="Password"
						space={SPACES.FORM}
						name="password"
						type={passwordType1}
						icons={
							<div aria-hidden="true" className={`eye-${passwordType1}`} onClick={onTogglePasswordEye1}>
								<img src={eye} alt="" />
							</div>
						}
					/>
					<InputContainer
						label="Repeat password"
						space={SPACES.FORM}
						name="password2"
						type={passwordType2}
						icons={
							<div aria-hidden="true" className={`eye-${passwordType2}`} onClick={onTogglePasswordEye2}>
								<img src={eye} alt="" />
							</div>
						}
					/>
					<ServerErrors space={SPACES.FORM} />
					<SubmitContainer space={SPACES.FORM}>
						<BorderButton large green>
							CHANGE
						</BorderButton>
					</SubmitContainer>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Form;
