import { ROUTE_PATH_KEYS, LINKS } from '@local/constants';
import actor from './Actor';
import layout from './layout';
import getArticleRoutes from '../article/getRoutes';

const getRoutes = () => ({
	index: 'list/1',
	links: {
		[LINKS.NEWS]: ({ id, pagination }) => (id ? `/article/${id}` : `/list/${pagination || 1}`),
	},
	children: {
		article: getArticleRoutes(),
		list: { actor, layout, params: [ROUTE_PATH_KEYS.PAGINATION] },
	},
});

export default getRoutes;
