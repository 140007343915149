import React, { useState } from 'react';
import './style.scss';
import classNames from 'classnames';
import dropUp from '../../../images/drop_up.svg';
import { InterectiveProps } from '../types';

interface ButtonAttrs<TValue> extends InterectiveProps<TValue> {
	children?: any;
}

const MenuDropDown = <TValue,>(props: ButtonAttrs<TValue>): JSX.Element => {
	const { children } = props;
	const [visible, setVisible] = useState(false);
	return (
		<div
			aria-hidden="true"
			className={classNames('menu-drop-down', {
				'menu-drop-down-visible': visible,
			})}
			tabIndex={-1}
			onMouseEnter={() => setVisible(true)}
			onMouseLeave={() => setVisible(false)}
		>
			<img src={dropUp} alt="" />
			<div className="drop-down">{children}</div>
		</div>
	);
};

export default MenuDropDown;
