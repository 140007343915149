import { useEffect, useState, VFC } from 'react';
import { useAppStore, reducersCreators, actions } from '@local/store';
import { COMMON_SPACES } from '@local/constants';
import { api } from '@local/services/api';
import { useEffectAsync } from '@local/hooks';
import { session } from '@local/services';

const authReducer = reducersCreators.valueReducer(null);
export const AuthActor: VFC = () => {
	const { useReducer, dispatch, useStoreSpace } = useAppStore();
	const auth = useStoreSpace(COMMON_SPACES.AUTH);
	const [counter, setCounter] = useState(0);

	useReducer(COMMON_SPACES.AUTH, authReducer);

	// login
	useEffectAsync(async () => {
		if (session.getItem() !== null) {
			const result = await api.apiGet('Ping');
			dispatch(actions.set(COMMON_SPACES.AUTH, result ?? { success: false }));
		} else dispatch(actions.set(COMMON_SPACES.AUTH, { success: false }));
	}, [counter]);

	// logout
	useEffectAsync(async () => {
		if (auth && auth.success === false && session.getItem() !== null) {
			await api.apiGet('RemoveSession');
			session.remove();
		}
	}, [auth]);

	useEffect(
		() =>
			session.subscribe(() => {
				setCounter((counter) => counter + 1);
			}),
		[]
	);

	return null;
};
