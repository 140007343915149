import React from 'react';
import { TopMenu, Footer, Pagination } from 'pages/common';
import { LINKS } from '@local/constants';
import { TitlePage } from '@local/components';
import SPACES from './SPACES';
import List from './List.tsx';
import Tags from './tags';
import './style.scss';
import { SubPagesButtons } from './SubPagesButtons';

const layout = () => {
	return (
		<div className="learn-container">
			<TopMenu />
			<section className="learn-container__section">
				<div className="title-text">
					<TitlePage>LEARNING</TitlePage>
					<TitlePage>CENTER</TitlePage>
				</div>
				<p className="under-title-text">Equip yourself with the tools and knowledge to reach enlightenment!</p>
				<SubPagesButtons />
				<Tags />
			</section>
			<div className="learn-container__table">
				<List space={SPACES.LIST} />
				<Pagination link={LINKS.LEARN} space={SPACES.LIST} />
			</div>
			<Footer />
		</div>
	);
};

export default layout;
