import React from 'react';
import { Footer } from 'pages/common';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { useAppStore, routeActions } from '@local/store';

import './CheckEmail.scss';
import arrowBack from 'images/arrow-back.svg';
import checked from 'images/checked.svg';

const CheckEmail: React.FC = () => {
	const { useAction } = useAppStore();
	const onBack = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }));
	return (
		<div className="check-email">
			<div className="check-email__container">
				<div className="check-email__content">
					<div aria-hidden="true" className="arrow-back" onClick={onBack}>
						<img src={arrowBack} alt="" />
					</div>
					<h1 className="title">CHECK YOUR EMAIL</h1>
					<h2 className="foreword">We have sent a link to reset a password to your email</h2>
					<img src={checked} alt="" />
				</div>
			</div>
			<Footer />
		</div>
	);
};

CheckEmail.displayName = 'CheckEmail';

export default CheckEmail;
