import { useAppStore } from '@local/store';
import { useCallback } from 'react';
import { ROUTE_PATH_KEYS, COMMON_SPACES } from '@local/constants';
import { sortByDate } from '@local/services';
import SPACES from './SPACES';

const blogsLoader = async ({ get }) => sortByDate(await get('blogs'));

const Actor = () => {
	const { useResource, useStoreSpace } = useAppStore();
	const id = useStoreSpace(COMMON_SPACES.PATH_PARAMS)?.[ROUTE_PATH_KEYS.ID];
	useResource(
		SPACES.ARTICLE,
		useCallback(({ get }) => get(`blogs/${id}`), [])
	);

	useResource(SPACES.OTHER_ARTICLES, blogsLoader);
	return null;
};

export default Actor;
