const LINKS = {
	PAGE: 'page',
	HOME: 'home',
	NEWS: 'news',
	ABOUT: 'about',
	LEARN: 'learn',
	ACCOUNT_SETTINGS: 'account-settings',
	/* LISA_SCRIPT: 'lisa-script', */
	ON_CHAIN: 'on-chain',
	PRICING: 'pricing',
};

export default LINKS;
