import { ROUTE_ACTION_SET_LINKS, ROUTE_ACTION_TYPE, ROUTE_ACTION_TYPE_ABS, ROUTE_ACTION_TYPE_UP } from './actionNames';

export const push = (link, payload) => ({
	type: ROUTE_ACTION_TYPE,
	payload: { method: 'push', link, payload },
});
export const replace = (link, payload) => ({
	type: ROUTE_ACTION_TYPE,
	payload: { method: 'replace', link, payload },
});
export const replaceAbs = (link, payload) => ({
	type: ROUTE_ACTION_TYPE_ABS,
	payload: { method: 'replace', link, payload },
});
export const up = (count, method) => ({
	type: ROUTE_ACTION_TYPE_UP,
	payload: { count, method },
});
export const setLinks = (links) => ({
	type: ROUTE_ACTION_SET_LINKS,
	payload: links,
});
