import React from 'react';
import { routeActions, useAppStore } from '@local/store';
import { BUTTON_VIEW, COMMON_SPACES, LINKS, ROUTE_PATH_KEYS } from '@local/constants';
import { Button, Swiper } from '@local/components';
import { SUB_PAGES } from 'pages/account_settings/SUB_PAGES';

export const SubPagesButtons: React.VFC = () => {
	const { useStoreSpace, useAction } = useAppStore();
	const subpage = useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.SUBPAGE);
	const onChange = useAction(({ target: { value } }) => routeActions.push(LINKS.ACCOUNT_SETTINGS, { subPage: value }));
	const buttonProps = { value: subpage, onChange };
	return (
		<Swiper>
			<Button {...buttonProps} activeValue={SUB_PAGES.INFO} view={BUTTON_VIEW.SMALL_ICON}>
				INFO
			</Button>
			<Button {...buttonProps} activeValue={SUB_PAGES.SECURITY} view={BUTTON_VIEW.SMALL_ICON}>
				SECURITY
			</Button>
			<Button {...buttonProps} activeValue={SUB_PAGES.UPGRADE} view={BUTTON_VIEW.SMALL_ICON}>
				UPGRADE
			</Button>
		</Swiper>
	);
};
