import { format, parseISO } from 'date-fns';

const DATE_FORMAT = 'MMM d';

const formatDate = (date?: string): string => {
	const parsedDate = date && parseISO(date);
	return parsedDate ? format(parsedDate, DATE_FORMAT) : '-';
};

export { formatDate };
