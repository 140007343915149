import React from 'react';
import { Footer, ServerErrors } from 'pages/common';
import { BorderButton } from '@local/components';
import { CodeContainer, SubmitContainer } from '@local/containers';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { useAppStore, routeActions } from '@local/store';
import SPACES from './SPACES';
import arrowBack from '../../images/arrow-back.svg';
import './style.scss';

const VerificationCode: React.FC = () => {
	const { useAction } = useAppStore();
	const onBack = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }));
	return (
		<div className="verification-password">
			<div className="verification-password__container">
				<div className="verification-password__content">
					<div aria-hidden="true" className="arrow-back" onClick={onBack}>
						<img src={arrowBack} alt="" />
					</div>
					<h1 className="title">VERIFICATION</h1>
					<h2 className="foreword">Please enter the verification code we sent to your email address</h2>
					<CodeContainer space={SPACES.FORM} />
					<ServerErrors space={SPACES.FORM} />
					<SubmitContainer space={SPACES.FORM}>
						<BorderButton large green>
							SUBMIT
						</BorderButton>
					</SubmitContainer>
				</div>
			</div>
			<Footer />
		</div>
	);
};

VerificationCode.displayName = 'VerificationCode';

export default VerificationCode;
