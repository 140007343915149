import React from 'react';
import logo from 'images/logo.png';
import twitter from 'images/twitter.svg';
import instagram from 'images/instagram.svg';
import linkedin from 'images/linkedin.svg';
import youtube from 'images/youtube.svg';
import 'pages/common/style.scss';
import { Button } from '@local/components';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { BUTTON_VIEW, LINKS } from '@local/constants';
import { routeActions, useAppStore } from '@local/store';

const disclaimer = 'https://chainview.us/disclaimer.txt';
const tos = 'https://chainview.us/tos.html';
const privacypolicy = 'https://chainview.us/privacypolicy.html';

const Footer: React.FC = () => {
	const { useAction } = useAppStore();
	return (
		<div className="footer-container">
			<Button
				onChange={useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.HOME }))}
				activeValue={PAGE_NAMES.HOME}
				view={BUTTON_VIEW.SMALL_ICON}
			>
				<img src={logo} alt="" />
			</Button>
			<div className="footer-container__chain-view">CHAINVIEW</div>
			<div className="footer-container__title">CHAINVIEW.US</div>
			<div className="footer-container__link-info">
				<img src={twitter} alt="twitter" />
				<img src={instagram} alt="instagram" />
				<img src={linkedin} alt="linkedin" />
				<img src={youtube} alt="youtube" />
			</div>
			<div className="footer-container__link-info footer-container__link-info-text">
				<div className="link">
					<a href={disclaimer}>DISCLAIMER</a>
				</div>
				<div className="link">
					<a href={privacypolicy}>PRIVACY POLICY</a>
				</div>
				<div className="link">
					<a href={tos}>TERMS OF SERVICE</a>
				</div>
			</div>
			<div className="footer-container__description">CHAINVIEW LLC - NEW YORK</div>
		</div>
	);
};

export default Footer;
