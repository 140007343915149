import React from 'react';

const VectorForms: React.FC = () => (
	<>
		<svg
			width="36"
			height="37"
			viewBox="0 0 36 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="vector__top"
		>
			<path
				d="M1.02295 36.4594V19.78C8.18978 12.5364 12.1504 8.53331 19.2229 1.38501H35.8198"
				stroke="#A8A8A8"
				strokeWidth="1.85781"
				strokeMiterlimit="10"
			/>
		</svg>
		<svg
			width="36"
			height="37"
			viewBox="0 0 36 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="vector__bottom"
		>
			<path
				d="M34.8066 0.799988V17.5747C27.7341 24.723 23.7734 28.726 16.6066 35.9696H0.00976562"
				stroke="#A8A8A8"
				strokeWidth="1.85781"
				strokeMiterlimit="10"
			/>
		</svg>
	</>
);

export default VectorForms;
