import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import SPACES from 'pages/register/SPACES';
import { InputContainer, SubmitContainer } from '@local/containers';
import { BorderButton, Button, Icon, Loader } from '@local/components';
import { LINKS } from '@local/constants';
import { routeActions, useAppStore, actions } from '@local/store';
import { ServerErrors, VectorForms } from 'pages/common';
import VECTOR from 'images/VECTOR_REGISTER.png';
import eye from '../../images/eye.svg';
import PAGE_NAMES from '../PAGE_NAMES';

const cookies = new Cookies();

const Form: React.VFC = () => {
	const { useAction, useStoreSpace } = useAppStore();
	const store = useStoreSpace(SPACES.FORM);
	const cookiesReferral = cookies.get('referral');

	const setRef = useAction(() => actions.setPath(SPACES.FORM, ['referral'], cookiesReferral));

	useEffect(() => {
		cookiesReferral && setRef();
	}, []);

	const [passwordType1, setPasswordType1] = useState<'password' | 'text'>('password');
	const [passwordType2, setPasswordType2] = useState<'password' | 'text'>('password');
	const onTogglePasswordEye1 = useCallback(() => {
		setPasswordType1((prev) => (prev === 'password' ? 'text' : 'password'));
	}, [setPasswordType1]);
	const onTogglePasswordEye2 = useCallback(() => {
		setPasswordType2((prev) => (prev === 'password' ? 'text' : 'password'));
	}, [setPasswordType2]);

	const onClose = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.HOME }));
	const onClickHaveAccount = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }));

	return (
		<section className="register__container">
			<div className="register__content">
				<VectorForms />
				<img className="vector" src={VECTOR} alt="" />
				<Icon icon={Icon.icons.CLOSE} title="close" onClick={onClose} className="close" />

				<div className="title">CREATE ACCOUNT</div>
				<div className="foreword">To sign up enter you details below</div>

				<InputContainer label="Username" space={SPACES.FORM} name="userName" />
				<InputContainer label="Email" space={SPACES.FORM} name="email" />
				<InputContainer
					label="Password"
					space={SPACES.FORM}
					name="password"
					type={passwordType1}
					icons={
						<div aria-hidden="true" className={`eye-${passwordType1}`} onClick={onTogglePasswordEye1}>
							<img src={eye} alt="" />
						</div>
					}
				/>
				<InputContainer
					label="Repeat password"
					space={SPACES.FORM}
					name="password2"
					type={passwordType2}
					icons={
						<div aria-hidden="true" className={`eye-${passwordType2}`} onClick={onTogglePasswordEye2}>
							<img src={eye} alt="" />
						</div>
					}
				/>

				<div className="referral-string">
					{cookiesReferral && `REFERRAL: ${cookiesReferral}`.toUpperCase()}
					{store?.submitting ? <Loader /> : <ServerErrors space={SPACES.FORM} />}
				</div>

				<SubmitContainer space={SPACES.FORM}>
					<BorderButton large green>
						CREATE
					</BorderButton>
				</SubmitContainer>
				<div className="have-account">
					Already have an account?
					<Button onChange={onClickHaveAccount}>Sign in</Button>
				</div>
			</div>
		</section>
	);
};

export default Form;
