import * as actionTypes from '../actionTypes';
import { setPath } from './utils';

const createResourceReducer = (value = null) => {
	const initState = { value, originalValue: value };
	return (state = initState, action) => {
		switch (action?.type) {
			case actionTypes.START_LOAD:
				return {
					...state,
					loading: true,
					loaded: false,
					loadError: null,
					value: null,
					originalValue: null,
					postData: action.postData,
				};
			case actionTypes.LOAD_SUCCESS:
				return {
					...state,
					loading: false,
					loaded: true,
					loadError: null,
					value: action?.payload,
					originalValue: action?.payload,
					changed: false,
				};
			case actionTypes.LOAD_ERROR:
				return {
					...state,
					loading: false,
					loaded: true,
					loadError: action?.payload,
					value: null,
					originalValue: null,
				};
			case actionTypes.START_RELOAD:
				return {
					...state,
					loading: true,
					loaded: false,
					loadError: null,
					value: null,
				};
			case actionTypes.SET:
				return {
					...state,
					changed: true,
					value: action?.payload,
				};
			case actionTypes.MERGE:
				return {
					...state,
					changed: true,
					value: { ...state.value, ...action?.payload },
				};
			case actionTypes.SET_PATH: {
				const { path, value } = action.payload;
				return {
					...state,
					changed: true,
					value: setPath(state.value, path, value),
				};
			}
			case actionTypes.SUBMIT:
				return state.validationErrors
					? {
							...state,
							submitting: false,
							submitted: true,
							// submitError: null,
					  }
					: {
							...state,
							submitting: action.payload ?? true,
							submitted: false,
							// submitError: null,
					  };
			case actionTypes.SUBMIT_SUCCESS:
				return {
					...state,
					submitting: false,
					submitted: true,
					submitError: null,
				};
			case actionTypes.SUBMIT_ERROR:
				return {
					...state,
					submitting: false,
					submitted: true,
					submitError: action?.payload,
				};
			case actionTypes.VALIDATION_ERRORS:
				return {
					...state,
					validationErrors: action?.payload,
				};
			case actionTypes.INVALIDATE:
				return initState;
			case actionTypes.RESET:
				return { ...initState, changed: false };
			default:
				return state;
		}
	};
};

export default createResourceReducer;
