import { ROUTE_PATH_KEYS, LINKS } from '@local/constants';
import actor from './Actor';
import layout from './layout';
import getArticleRoutes from '../article/getRoutes';
import SUB_PAGE_NAMES from './SUB_PAGE_NAMES';

const getRoutes = () => ({
	index: `${SUB_PAGE_NAMES.MARKET}/0/list/1`,
	links: {
		[LINKS.LEARN]: ({ id, subPage, tag, pagination }, { [ROUTE_PATH_KEYS.SUBPAGE]: curSubPage } = {}) =>
			`/${subPage ?? curSubPage ?? SUB_PAGE_NAMES.MARKET}/${tag ?? '0'}${
				id ? `/article/${id}` : `/list/${pagination || 1}`
			}`,
	},
	params: [ROUTE_PATH_KEYS.SUBPAGE, ROUTE_PATH_KEYS.TAG],
	children: {
		list: { actor, layout, params: [ROUTE_PATH_KEYS.PAGINATION] },
		article: getArticleRoutes(),
	},
});

export default getRoutes;
