import React from 'react';

import './index.scss';

// interface Props {
// 	options: Array<{ title: string; selected?: boolean }>;
// }

const Swiper: React.FC = ({ children }) => {
	// const optionsJSX = useMemo(
	// 	() =>
	// 		options.map(({ title, selected }) => (
	// 			<div key={title} className={['swiper__item', selected ? ' selected' : ''].join(' ')}>
	// 				{title}
	// 			</div>
	// 		)),
	// 	[options]
	// );

	return <div className="swiper__container">{children}</div>;
};

export default Swiper;
