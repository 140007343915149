import { routeActions, useAppStore } from '@local/store';
import * as yup from 'yup';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import SPACES from './SPACES';

const schema = yup.object().shape({
	password: yup.string().required(),
	password2: yup.string().required(),
});

const Actor = () => {
	const { useForm, dispatch } = useAppStore();
	const accountPass = JSON.parse(localStorage.getItem('v') ?? {});
	useForm({
		space: SPACES.FORM,
		schema,
		submitter: ({ apiPost }, value) => apiPost('ChangePassword', value, false, accountPass),
		onSaved: () => {
			dispatch(routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }));
		},
	});
	return null;
};

export default Actor;
