import { Footer } from 'pages/common';
import React from 'react';
import Form from './Form';

import './style.scss';

const layout = () => (
	<main className="sign-in">
		<Form />
		<Footer />
	</main>
);

export default layout;
