import { memo, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { COMMON_SPACES, LINKS, ENV } from '@local/constants';
import { useAppStore, routeActions, actions } from '@local/store';
import getRoutes from 'pages/getRoutes';
import Router from './Router';

function flatten(arr) {
	return arr.reduce(function (flat, toFlatten) {
		return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
	}, []);
}

const getLinks = (routes, path = ['']) => [
	...(routes?.links ? Object.entries(routes.links).map(([key, link]) => ({ key, path, link })) : []),
	...(routes?.children
		? Object.entries(routes.children).map(([key, subRoutes]) =>
				getLinks(subRoutes, [...path, ...(routes.params ? routes.params.map(() => null) : []), key])
		  )
		: []),
];

const getLinksObj = (routes, links) => {
	const linksArray = flatten(getLinks(routes)).map(({ key, link, path }) => [key, { link, path }]);

	const incorrectLinks = linksArray.filter(([key]) => !links.includes(key));
	if (incorrectLinks.length > 0) console.error(`Incorrect Links ${JSON.stringify(incorrectLinks, null, 2)}`);
	return Object.fromEntries(linksArray);
};

const fromHomePage = ENV.HOMEPAGE
	? (pathname) => pathname && `/${pathname?.slice(ENV.HOMEPAGE.length)}`
	: (pathname) => pathname;

const RouterContainer = memo(() => {
	const { dispatch, useAction, history, useStoreSpace } = useAppStore();
	const auth = useStoreSpace(COMMON_SPACES.AUTH);
	const [pathname, setPathname] = useState(fromHomePage(history.location?.pathname));
	const isAuth = auth?.success;
	useLayoutEffect(() => {
		const clear = history.listen((e) => {
			setPathname(() => fromHomePage(e.location?.pathname));
			window.scrollTo(0, 0);
		});
		setPathname(() => fromHomePage(history.location?.pathname));
		return clear;
	}, [history]);

	const routes = useMemo(() => getRoutes(isAuth), [isAuth]);

	useEffect(() => {
		dispatch(routeActions.setLinks(getLinksObj(routes, Object.values(LINKS))));
	}, [dispatch, routes]);
	const onRedirectHandler = useAction((path) => routeActions.replaceAbs(path));
	const onChangeHandler = useAction(({ params }) => actions.set(COMMON_SPACES.PATH_PARAMS, params));
	return auth ? (
		<Router routes={routes} path={pathname} onRedirect={onRedirectHandler} onChange={onChangeHandler} isAuthCorrect />
	) : null;
});

export default RouterContainer;
