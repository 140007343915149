const KEY = 't';

interface THandler {
	(v: string | null): void;
}
interface TClear {
	(): void;
}

let handlers: THandler[] = [];
const getItem = (): string | null => localStorage.getItem(KEY);
const setItem = (obj: string): void => {
	localStorage.setItem(KEY, obj);
	handlers.forEach((h) => h(null));
};

export const session = {
	subscribe: (callback: THandler): TClear => {
		let prev = getItem();
		const handler = () => {
			const newValue = getItem();
			if (prev !== newValue) {
				prev = newValue;
				callback(newValue);
			}
		};
		window.addEventListener('storage', handler);
		handlers = [...handlers, handler];
		return () => {
			window.removeEventListener('storage', handler);
			handlers = handlers.filter((item) => item !== handler);
		};
	},
	getItem,
	setItem,
	remove: (): void => {
		localStorage.removeItem(KEY);
		handlers.forEach((h) => h(null));
	},
};
