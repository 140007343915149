import { TopMenu, Footer } from 'pages/common';
import './style.scss';
import { TitlePage } from '@local/components';
import browser from '../../images/browser-about-us.png';

const layout = () => (
	<div className="about-page">
		<TopMenu />
		<div className="about-page__content">
			<TitlePage>ABOUT US</TitlePage>
			<div className="about-page__title">
				{"We've managed to combine market and onchain data in a single platform."}
			</div>

			<div className="about-page__description">
				{'All the data you want for your needs, trade without limits with chainview.'}
			</div>

			<div className="about-page__updatesTitle">Updates and Changes</div>

			<div className="about-page__updatesDescription">
				You can find more about our changes and updates on our <span className="link">Blog</span> or{' '}
				<span className="link">Discord</span>.
			</div>

			<div className="about-page__contactTitle">Contact Us</div>

			<div className="about-page__description">support@chainview.us</div>

			<img src={browser} alt="browser" />
		</div>
		<Footer />
	</div>
);

export default layout;
