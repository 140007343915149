import React, { useCallback, useState } from 'react';
import { InputContainer } from '@local/containers';
import { Button } from '@local/components';
import { BUTTON_VIEW } from '@local/constants';
import { useAppStore, actions } from '@local/store';
import { SAVE_TARGETS } from '../SAVE_TARGETS';
import eye from '../../../images/eye.svg';
import SPACES from '../SPACES';

import './info.scss';

const mail = 'support@chainview.us';

const Info: React.FC = () => {
	const { useAction } = useAppStore();

	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
	const onTogglePasswordEye = useCallback(() => {
		setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));
	}, [setPasswordType]);

	const onClickEmail = useAction(() => actions.submit(SPACES.SETTINGS, SAVE_TARGETS.EMAIL));
	const onClickPassword = useAction(() => actions.submit(SPACES.SETTINGS, SAVE_TARGETS.PASSWORD));
	const onClickUsername = useAction(() => actions.submit(SPACES.SETTINGS, SAVE_TARGETS.NAME));

	const onOpenMail = useCallback(() => window.open(`mailto:${mail}`), []);

	return (
		<article className="settings-info">
			<h1 className="title">Account Info</h1>
			<div className="input-wrap">
				<InputContainer label="Username" space={SPACES.SETTINGS} name="userName" />
				<Button onChange={onClickUsername} view={BUTTON_VIEW.UNDERLINED_LINK}>
					Change username
				</Button>
			</div>
			<div className="input-wrap">
				<InputContainer label="Email" space={SPACES.SETTINGS} name="email" />
				<Button onChange={onClickEmail} view={BUTTON_VIEW.UNDERLINED_LINK}>
					Change email
				</Button>
			</div>
			<div className="input-wrap">
				<InputContainer
					label="Password"
					space={SPACES.SETTINGS}
					name="password"
					type={passwordType}
					icons={
						<div aria-hidden="true" className={`eye-${passwordType}`} onClick={onTogglePasswordEye}>
							<img src={eye} alt="" />
						</div>
					}
				/>
				<Button onChange={onClickPassword} view={BUTTON_VIEW.UNDERLINED_LINK}>
					Change password
				</Button>
			</div>
			<p className="foreword">
				Are you experiencing problems with your account? Contact us by email at
				<Button onChange={onOpenMail}>{mail}</Button>
			</p>
		</article>
	);
};

Info.displayName = 'Info';

export default Info;
