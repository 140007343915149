import React from 'react';
import { TopMenu, Footer } from 'pages/common';
import { TLayout } from '@local/dataTypes';

import { TitlePage } from '@local/components';
import { SubPagesButtons } from './SubPagesButtons';
import './style.scss';

const commonLayout: TLayout = (SubComponent: React.ComponentType) => (
	<div className="account-setting">
		<TopMenu />
		<section className="account-setting__section">
			<div className="title-text">
				<TitlePage>ACCOUNT</TitlePage>
				<TitlePage>SETTINGS</TitlePage>
			</div>
			<SubPagesButtons />
		</section>
		<SubComponent />
		<Footer />
	</div>
);

export default commonLayout;
