import React from 'react';
import classname from 'classnames';
import { Label } from '../Label';
import { InterectiveProps } from '../types';
import useShowErrors from '../useShowErrors';

import './style.scss';

export interface InputType extends InterectiveProps<string> {
	label?: string;
	type?: string;
	icons?: any;
}

export const Input: React.FC<InputType> = ({
	label,
	name,
	value,
	onChange,
	className,
	errors,
	submitted,
	icons,
	...rest
}) => {
	const [filteredErrors, inputProps] = useShowErrors<string>({
		errors,
		submitted,
		onChange,
	});

	return (
		<Label label={label} errors={filteredErrors} className="label-input">
			<div className={classname('Input', { 'Input--error': filteredErrors.length })}>
				<input type="text" name={name} value={value} {...inputProps} {...rest} />
				<div className="icons">{icons}</div>
			</div>
		</Label>
	);
};
