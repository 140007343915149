import React, { FC, memo } from 'react';
import { TitlePage } from '@local/components';
import { routeActions, useAppStore } from '@local/store';
import { ENV, LINKS } from '@local/constants';
import { format, parseISO } from 'date-fns';

const DATE_FORMAT = 'MMM d';
export const formatDate = (date?: string): string => {
	const parsedDate = date && parseISO(date);
	return parsedDate ? format(parsedDate, DATE_FORMAT) : '-';
};

export const NewsArticle: FC<{ space: string }> = memo(({ space }) => {
	const { useStoreSpace, useAction } = useAppStore();
	const { value = [] } = useStoreSpace(space) ?? {};
	const [article] = value ?? [];

	const onChange = useAction(() => routeActions.push(LINKS.NEWS, { id: article?.id }));

	return (
		<div className="news-container__first-section">
			<TitlePage>NEWS</TitlePage>
			<div className="news-container__slider-container" onClick={onChange} aria-hidden="true">
				<img src={`${ENV.API_ROOT}${article?.thumb?.[0]?.formats?.thumbnail?.url}`} alt="preview" />
				<div className="news-container__slider-body">
					<div className="news-container__slider-title">{article?.title}</div>
					<div className="news-container__slider-description">{article?.description}</div>
					<div className="news-container__user-info">
						<img
							className="avatar"
							src={`${ENV.API_ROOT}${article?.author?.pic?.[0]?.formats?.thumbnail?.url}`}
							alt="avatar"
						/>
						<div className="description">
							<div>{article?.author?.name}</div>
							<div className="date">{formatDate(article?.created_at)}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

NewsArticle.displayName = 'NewsArticle';
