import React from 'react';
import './style.scss';
import { InterectiveProps } from '../types';

interface ButtonAttrs<TValue> extends InterectiveProps<TValue> {
	children?: string;
	large?: boolean;
}

const TitlePage = <TValue,>(props: ButtonAttrs<TValue>): JSX.Element => {
	const { children, large = false } = props;
	const t = children?.split(' ');
	return (
		<div className={large ? 'title-page-large title-page' : 'title-page'}>
			{t?.map((text, id) => (
				<div className="title-page__item" key={id}>
					<div>{text.toUpperCase()}</div>
					<div>{text.toUpperCase()}</div>
				</div>
			))}
		</div>
	);
};

export default TitlePage;
