import { useAppStore } from '@local/store';
import { COMMON_SPACES } from '@local/constants';
import SPACES from './SPACES';

const loader = ({ apiGet }) => apiGet('UserInfo');
const toReject = () => Promise.reject();

const Actor = () => {
	const { useForm, useStoreSpace } = useAppStore();
	const auth = useStoreSpace(COMMON_SPACES.AUTH);
	useForm({
		space: SPACES.INFO,
		loader: auth?.success ? loader : toReject,
	});
	return null;
};

export default Actor;
