import React, { useMemo } from 'react';
import { useAppStore, routeActions } from '@local/store';
import { COMMON_SPACES, EMPTY, ENV, LINKS, ROUTE_PATH_KEYS } from '@local/constants';
import ListItem from './ListItem';

import './list.scss';

interface Props {
	space: string;
}

const List: React.FC<Props> = ({ space }) => {
	const { useStoreSpace, useAction } = useAppStore();
	const { value, loaded } = useStoreSpace(space) ?? {};
	const selectedTag = Number(useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.TAG)) || 0;
	const onClick = useAction(({ target: { value } }) => routeActions.push(LINKS.LEARN, { id: value?.id }));

	const page = Math.max(Number(useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.PAGINATION)) || 1, 1);

	const list = useMemo(() => {
		const listArray = Array.isArray(value) ? value : EMPTY.ARRAY;
		const filtered =
			selectedTag > 0 ? listArray.filter(({ tags }) => tags.some(({ id }) => id === selectedTag)) : listArray;
		return filtered.slice((page - 1) * ENV.PAGES_LIMIT, page * ENV.PAGES_LIMIT);
	}, [selectedTag, value, page]);

	return (
		<div className="learn-list__container">
			{loaded && list.map((article, i) => <ListItem key={i} value={article} onChange={onClick} />)}
		</div>
	);
};

export default List;
