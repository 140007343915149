import React from 'react';
import Info from './Info';
import Plan from './Plan';
import RefSystem from './RefSystem';

import './index.scss';

const WrapInfo: React.FC = () => {
	return (
		<section className="settings-info-wrap">
			<Info />
			<Plan />
			<RefSystem />
		</section>
	);
};

WrapInfo.displayName = 'WrapInfo';

export default WrapInfo;
