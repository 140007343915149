import * as yup from 'yup';
import { routeActions, useAppStore } from '@local/store';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { session } from '@local/services';
import SPACES from './SPACES';

const schema = yup.object().shape({
	userName: yup.string().required(),
	password: yup.string().max(20, 'Must be 20 characters or less').required(),
});

const Actor = () => {
	const { useForm, dispatch } = useAppStore();

	useForm({
		space: SPACES.FORM,
		schema,
		submitter: ({ apiPost }, value) => apiPost('Login', value),
		onSaved: (resp) => {
			session.setItem(JSON.stringify(resp ?? null));
			dispatch(routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.HOME }));
		},
	});
	return null;
};

export default Actor;
