import React, { useCallback, useState } from 'react';
import { BorderButton, Button, ChangeEventType, Switch } from '@local/components';
import { LINKS, PLANS } from '@local/constants';
import { api } from '@local/services';
import { useAppStore, routeActions, actions } from '@local/store';
import moment from 'moment';
import { useEffectAsync } from '@local/hooks';
import { SUB_PAGES } from '../SUB_PAGES';
import SPACES from '../SPACES';
import { SAVE_TARGETS } from '../SAVE_TARGETS';

import './plan.scss';

interface Settings {
	value?: { autoPlanRenew?: boolean };
}

interface BillingInstanceOrigin {
	hostedBillingPortalUrl?: string;
}

const Plan: React.FC = () => {
	const [billingPortalLink, setBillingPortalLink] = useState('');
	const { useStoreSpace, useAction, dispatch } = useAppStore();
	const { value }: Settings = useStoreSpace(SPACES.SETTINGS) ?? {};
	const info = useStoreSpace(SPACES.INFO);

	const swapPlans = PLANS ? Object.fromEntries(Object.entries(PLANS).map(([k, v]) => [v, k])) : {};

	const onClickChangePlan = useAction(() => routeActions.push(LINKS.ACCOUNT_SETTINGS, { subPage: SUB_PAGES.UPGRADE }));
	const onChange = useCallback(
		({ target: { name, value } }: ChangeEventType<boolean>) => {
			dispatch(actions.setPath(SPACES.SETTINGS, [name], value));
			dispatch(actions.submit(SPACES.SETTINGS, SAVE_TARGETS.AUTO_PLAN_RENEW));
		},
		[dispatch]
	);

	const renewDate = moment(new Date(info?.value?.planExpiration)).local().format('Do MMMM YYYY');

	const renewsDays = moment().diff(
		moment(new Date(info?.value?.planExpiration)).local().format('YYYY-MM-D'),
		'days',
		false
	);

	useEffectAsync(async () => {
		const res = await api.apiPost<BillingInstanceOrigin>('billingportal', {});
		if (res?.success) setBillingPortalLink(res?.data?.hostedBillingPortalUrl || '');
	}, []);

	return (
		<article className="settings-plan">
			<h1 className="title">Current Plan</h1>
			<p className="foreword">{swapPlans[info?.value?.plan]}</p>
			<p className="renews">
				{info?.value?.planExpiration && value?.autoPlanRenew
					? `Renews in ${String(renewsDays).replace('-', '')} days (${renewDate})`
					: ''}
			</p>
			<div className="auto-renew">
				Auto-renew is {value?.autoPlanRenew ? 'enabled' : 'disabled'}
				<Switch value={value?.autoPlanRenew} name={SAVE_TARGETS.AUTO_PLAN_RENEW} onChange={onChange} />
			</div>
			<Button onChange={onClickChangePlan} className="change-plan">
				<BorderButton green>CHANGE PLAN</BorderButton>
			</Button>
			{info?.value?.stripeMid && billingPortalLink !== '' && (
				<a href={billingPortalLink} target="blank" className="billing-portal">
					Billing portal
				</a>
			)}
			{info?.value?.referralName && (
				<div className="ref-invite">{`Referral Invite: ${info.value.referralName}`}</div>
			)}
		</article>
	);
};

Plan.displayName = 'Plan';

export default Plan;
