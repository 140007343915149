import React from 'react';
import classnames from 'classnames';
import './style.scss';
import { InterectiveProps } from '../types';
import account from '../../../images/account.svg';

interface ButtonAttrs<TValue> extends InterectiveProps<TValue> {
	activeValue?: TValue;
	view?: string;
	className?: string;
}

const Account = () => (
	<div className="ch-button-account">
		<img src={account} alt="account" />
		<div>ACCOUNT</div>
	</div>
);

const Button = <TValue,>(props: ButtonAttrs<TValue>): JSX.Element => {
	const { children, activeValue, name, value, onChange, view = 'link', className = '' } = props;
	return (
		<button
			className={classnames('ch-button', `ch-button-view-${view}`, {
				'ch-button-active': activeValue === undefined ? value : activeValue === value,
				[className]: !!className,
			})}
			onClick={(...args) => {
				if (onChange) {
					onChange({
						target: { name, value: (activeValue === undefined ? !value : activeValue) as TValue },
					});
					args[0].stopPropagation();
				}
			}}
		>
			{children}
		</button>
	);
};

Button.Account = Account;

export default Button;
