import { TopMenu, Footer, Article } from 'pages/common';
import './style.scss';
import SPACES from './SPACES';

const layout = () => (
	<div className="article-page">
		<div className="first-section">
			<TopMenu />
			<Article space={SPACES.ARTICLE} otherArticlesSpace={SPACES.OTHER_ARTICLES} />
		</div>
		<div className=" second-section">
			<Footer />
		</div>
	</div>
);

export default layout;
