import { LINKS, ROUTE_PATH_KEYS } from '@local/constants';
import { TRouteCreator } from '@local/dataTypes';
import { SUB_PAGES } from './SUB_PAGES';
import Info from './info';
import actor from './Actor';
import commonLayout from './commonLayout';
import Security from './security';
import Upgrade from './upgrade';

const getRoutes: TRouteCreator = () => ({
	index: `${SUB_PAGES.INFO}`,
	links: {
		[LINKS.ACCOUNT_SETTINGS]: ({ subPage }) => `/${subPage ?? SUB_PAGES.INFO}`,
	},
	childKey: ROUTE_PATH_KEYS.SUBPAGE,
	children: {
		[SUB_PAGES.INFO]: { layout: () => commonLayout(Info), actor },
		[SUB_PAGES.SECURITY]: { layout: () => commonLayout(Security), actor },
		[SUB_PAGES.UPGRADE]: { layout: () => commonLayout(Upgrade), actor },
	},
});

export default getRoutes;
