import React from 'react';
import Cookies from 'universal-cookie';
import { TopMenu, Footer } from 'pages/common';
import './style.scss';
import twitter from 'images/twitter.svg';
import instagram from 'images/instagram.svg';
import linkedin from 'images/linkedin.svg';
import youtube from 'images/youtube.svg';
import { Button, BorderButton, TitlePage } from '@local/components';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { useAppStore, routeActions } from '@local/store';
import { COMMON_SPACES, LINKS } from '@local/constants';
import loremButton from '../../images/lorem-button.png';
import ipsumButton from '../../images/ipsum-button.png';
import duisButton from '../../images/duis-button.png';
import auteButton from '../../images/aute-button.png';

const cookies = new Cookies();

const SignButton = () => {
	const { useAction, useStoreSpace } = useAppStore();
	const store = useStoreSpace(COMMON_SPACES.AUTH);
	return (
		<Button
			activeValue={store?.success}
			onChange={useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }))}
		>
			<BorderButton green>SIGN IN</BorderButton>
		</Button>
	);
};

const layout: React.VFC = () => {
	const { history } = useAppStore();
	const pathName = history.location.pathname.split('/');
	const refNameRoute = pathName[pathName.length - 1];
	if (refNameRoute.includes('ref'))
		cookies.set('referral', refNameRoute.split('_')[1], { path: history.location.pathname });

	return (
		<div className="home-page">
			<div className="home-page__first-section">
				<TopMenu />
				<div className="home-page__learn-trade-title">
					<TitlePage large>Simple. Fast. Powerfull.</TitlePage>
					<div className="home-page__navigate-title">Realtime market data and on-chain platform</div>
					<SignButton />
				</div>

				<div className="home-page__divider-container">
					<div className="item">
						<img src={twitter} alt="twitter" />
						<div className="divider" />
						<img src={instagram} alt="instagram" />
					</div>
					<div>scroll</div>
					<div className="item">
						<img src={linkedin} alt="linkedin" />
						<div className="divider" />
						<img src={youtube} alt="youtube" />
					</div>
				</div>
			</div>
			<div className="home-page__second-section">
				<div className="home-page__second-section--container">
					<div className="firstImg" />
					<div>
						<div className="home-page__second-section--title">HEATMAP & DATA PROCESSING</div>
						<div className="home-page__second-section--description">
							With non-uniform heatmap you can view orders easily and grouping orders based on price change.
							Non-uniform heatmaps are more reliable for profitable trading activity. You can use the heatmap in
							four different modes HD, FULL HD, 4K and 8K, each mode calculates a percentage in the price
							variation. Market data is processed without delay, our servers run a data flow of 3500 GB per day,
							up to peaks of 8000 GB. We process thousands of pairs from different exchanges and hundreds of
							thousands transactions per day.
						</div>
						<Button activeValue={PAGE_NAMES.LEARN_MORE}>
							<BorderButton yellow>LEARN MORE</BorderButton>
						</Button>
					</div>
				</div>

				<div className="home-page__second-section--second-container-title">
					FULL AND REAL-TIME ORDERBOOK FOR THOUSANDS OF PAIRS. REAL ON CHAIN DATA. INFLOW AND OUTFLOW WITHOUT
					LIMITS.
				</div>

				<div className="home-page__second-section--container home-page__second-section--container-last">
					<div>
						<div className="home-page__second-section--title">DATA FLOW</div>
						<div className="home-page__second-section--description">
							We are faster than exchanges, we distribute data faster than normal exchange app or desktop
							clients. We sends everything, without limits.
						</div>
						<Button activeValue={PAGE_NAMES.LEARN_MORE}>
							<BorderButton yellow>LEARN MORE</BorderButton>
						</Button>
					</div>
					<div className="secondImg" />
				</div>

				<div className="home-page__last-section--container">
					<div className="home-page__last-section--container--title">CHAINVIEW PIPELINE</div>
					<div className="home-page__last-section--container--description">
						Our capture, processing and forwarding pipeline are standardized, ChainView is managed in a
						distributed and can scale according to the respective number of users on our platform, even during
						times of extreme volatility.
					</div>
					<div className="btn-container">
						<img src={loremButton} alt="" />
						<img src={ipsumButton} alt="" />
						<img src={duisButton} alt="" />
						<img src={auteButton} alt="" />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default layout;
