import { VFC } from 'react';
import { useAppStore, reducersCreators } from '@local/store';
import { COMMON_SPACES } from '@local/constants';

const pathParamsReducer = reducersCreators.valueReducer({});
const AppActor: VFC = () => {
	const { useReducer } = useAppStore();
	useReducer(COMMON_SPACES.PATH_PARAMS, pathParamsReducer);
	return null;
};

export default AppActor;
