import { useAppStore, routeActions, actions } from '@local/store';
import React, { useCallback, useState } from 'react';
import { LINKS, COMMON_SPACES, ROUTE_PATH_KEYS, BUTTON_VIEW } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { BorderButton, Button, MenuDropDown } from '@local/components';
import logo from 'images/logo.png';
import menu from 'images/menu.svg';
import close from 'images/close_big.svg';
import 'pages/common/style.scss';

const TopMenu: React.FC = () => {
	const [visible, setVisible] = useState<boolean>(false);
	const { useStoreSpace, useAction } = useAppStore();
	const auth = useStoreSpace(COMMON_SPACES.AUTH);

	const value = useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.PAGE);
	const onChange: (event: React.SyntheticEvent<EventTarget>) => void = useAction(({ target: { value } }) =>
		routeActions.push(LINKS.PAGE, { page: value })
	);
	const onClickRedirect = async (event) => {
		await onChange(event);
		setVisible(false);
	};
	const buttonProps = { value, onChange: onClickRedirect };
	const onSignOut = useAction(() => actions.set(COMMON_SPACES.AUTH, { success: false }));

	const signIn = (
		<Button {...buttonProps} activeValue={auth?.success ? PAGE_NAMES.ACCOUNT_SETTINGS : PAGE_NAMES.SIGN_IN}>
			{auth?.success ? <Button.Account /> : <BorderButton green>SIGN IN</BorderButton>}
		</Button>
	);
	const signOut = auth?.success ? <Button onChange={onSignOut}>SIGN OUT</Button> : null;

	const onOpenOnChain = useCallback(() => window.open('https://chainview.us/onchain', '_self'), []);

	const onOpenChart = useCallback(() => window.open('https://chainview.us/chart', '_self'), []);

	return (
		<div className="top-menu-container">
			<Button {...buttonProps} activeValue={PAGE_NAMES.HOME} view={BUTTON_VIEW.SMALL_ICON}>
				<img src={logo} alt="" />
			</Button>
			<div className={visible ? 'visible-modal' : 'container-button'}>
				<div className="close" aria-hidden="true" onClick={() => setVisible(false)}>
					<img src={close} alt="close" />
				</div>
				<Button {...buttonProps} activeValue={PAGE_NAMES.NEWS} view={BUTTON_VIEW.LINK}>
					NEWS
				</Button>
				<Button {...buttonProps} activeValue={PAGE_NAMES.LEARN} view={BUTTON_VIEW.LINK}>
					LEARN
				</Button>
				<Button {...buttonProps} activeValue={PAGE_NAMES.PRICING} view={BUTTON_VIEW.LINK}>
					PRICING
				</Button>
				<Button {...buttonProps} activeValue={PAGE_NAMES.ON_CHAIN} view={BUTTON_VIEW.LINK} onChange={onOpenOnChain}>
					ON CHAIN
				</Button>
				<Button {...buttonProps} activeValue={PAGE_NAMES.ABOUT} view={BUTTON_VIEW.LINK}>
					ABOUT US
				</Button>
				{/* <Button {...buttonProps} activeValue={PAGE_NAMES.LISA_SCRIPT} view={BUTTON_VIEW.LINK}>
					LISA SCRIPT
				</Button> */}
				{signIn}
				{signOut}
			</div>
			<div className="sign-in-btn">
				{auth?.success && (
					<Button {...buttonProps} activeValue={PAGE_NAMES.CHART} onChange={onOpenChart}>
						<BorderButton green>CHART</BorderButton>
					</Button>
				)}
				<div className="icons-auth-success">
					{signIn}
					{auth?.success && <MenuDropDown>{signOut}</MenuDropDown>}
				</div>
			</div>
			<div className="hamburger-btn" aria-hidden="true" onClick={() => setVisible(true)}>
				<img src={menu} alt="menu" />
			</div>
		</div>
	);
};

export default TopMenu;
