import { ROUTE_PATH_KEYS, LINKS } from '@local/constants';
import getHomeRoutes from 'pages/home/getRoutes';
import getAccountSettingsRoutes from 'pages/account_settings/getRoutes';
import getAboutRoutes from './about/getRoutes';
import getNewsRoutes from './news/getRoutes';
import getSigninRoutes from './signin/getRoutes';
import getRegisterRoutes from './register/getRoutes';
import getLearnRoutes from './learn/getRoutes';
import getPricingRoutes from './pricing/getRoutes';
import getResetPasswordRoutes from './forgot_password/getRoutes';
import getVerificationPasswordRoutes from './verification_code/getRoutes';
import getChangePasswordRoutes from './change_password/getRoutes';
/* import getLisaScriptRoutes from './lisaScript/getRoutes'; */

import PAGE_NAMES from './PAGE_NAMES';

const getRoutes = (auth) => ({
	index: PAGE_NAMES.HOME,
	links: {
		[LINKS.PAGE]: ({ page }) => `/${page}`,
	},
	childKey: ROUTE_PATH_KEYS.PAGE,
	children: {
		[PAGE_NAMES.HOME]: getHomeRoutes(),
		[PAGE_NAMES.NEWS]: getNewsRoutes(),
		[PAGE_NAMES.LEARN]: getLearnRoutes(),
		[PAGE_NAMES.RESET_PASSWORD]: !auth && getResetPasswordRoutes(),
		[PAGE_NAMES.VERIFICATION_CODE]: !auth && getVerificationPasswordRoutes(),
		[PAGE_NAMES.CHANGE_PASSWORD]: !auth && getChangePasswordRoutes(),
		[PAGE_NAMES.SIGN_IN]: !auth && getSigninRoutes(),
		[PAGE_NAMES.REGISTER]: !auth && getRegisterRoutes(),
		[PAGE_NAMES.ABOUT]: getAboutRoutes(),
		[PAGE_NAMES.PRICING]: getPricingRoutes(),
		/* [PAGE_NAMES.LISA_SCRIPT]: getLisaScriptRoutes(), */
		[PAGE_NAMES.ACCOUNT_SETTINGS]: auth && getAccountSettingsRoutes(),
	},
});

export default getRoutes;
