import { actions, useAppStore } from '@local/store';
import { Input } from '@local/components';
import React, { useMemo, VFC } from 'react';

interface Props {
	space: string;
	name: string;
	label?: string;
	type?: string;
	icons?: any;
}

export const InputContainer: VFC<Props> = ({ space, name, label, type, icons }) => {
	const { useStoreSpace, useAction } = useAppStore();

	const { value, validationErrors } = useStoreSpace(space) ?? {};

	const errors = useMemo(
		() =>
			(validationErrors ?? [])
				.filter(({ path }) => path[0] === name)
				.map(({ path: [, ...path], ...other }) => ({
					...other,
					path,
				})),
		[validationErrors, name]
	);

	return (
		<Input
			label={label}
			name={name}
			value={value?.[name] ?? ''}
			errors={errors}
			type={type}
			icons={icons}
			onChange={useAction(({ target: { name, value } }) => actions.setPath(space, [name], value))}
		/>
	);
};
