import React, { useCallback } from 'react';
import classNames from 'classnames';
import sprite from '@local/assets/sprite.svg';
import { ChangeEventType } from '@local/components/types';

import './style.css';

const icons = {
	TWITTER: 'twitter',
	INSTAGRAM: 'instagram',
	LINKEDIN: 'linkedin',
	YOUTUBE: 'youtube',
	LEARN_TRADE_INVEST: 'learn-trade-invest',
	HAMBURGER: 'hamburger',
	CLOSE: 'close',
	EYE: 'eye',
	ARROW: 'arrow',
	CHECKED: 'checked',
	ACCOUNT: 'account',
	BIN: 'bin',
	SHIELD: 'shield',
	ARROW_UP: 'arrow-up',
	BORDER_GREEN: 'border-green',
	BORDER_GREEN_LARGE: 'border-green-large',
	BORDER_YELLOW: 'border-yellow',
	BORDER_GREY: 'border-grey',
};

interface IconAttrs {
	icon?: string;
	title?: string;
	className?: string;
	name?: string;
	value?: string | number | null;
	onClick?: (target: ChangeEventType<string | number | null | undefined>) => void;
}

const Icon = (props: IconAttrs): JSX.Element => {
	const { icon = Icon.icons.TWITTER, title = '', className, onClick, name, value } = props;

	const onClickHandler = useCallback(() => {
		onClick && onClick({ target: { name, value } });
	}, [onClick, name, value]);

	return (
		<svg className={classNames('icon', className)} aria-label={title} id={icon} onClick={onClickHandler}>
			<use href={`${sprite}#${icon}`} />
		</svg>
	);
};

Icon.icons = icons;

export default Icon;
