import React from 'react';
import { Footer, ServerErrors } from 'pages/common';
import { BorderButton } from '@local/components';
import { InputContainer, SubmitContainer } from '@local/containers';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import { useAppStore, routeActions } from '@local/store';
import SPACES from './SPACES';
import arrowBack from '../../images/arrow-back.svg';
import './ResetPassword.scss';

const ResetPassword: React.FC = () => {
	const { useAction } = useAppStore();
	const onBack = useAction(() => routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.SIGN_IN }));
	return (
		<div className="reset-password">
			<div className="reset-password__container">
				<div className="reset-password__content">
					<div aria-hidden="true" className="arrow-back" onClick={onBack}>
						<img src={arrowBack} alt="" />
					</div>
					<h1 className="title">RESET PASSWORD</h1>
					<h2 className="foreword">Enter the email associated with your account</h2>
					<InputContainer label="Email" space={SPACES.FORM} name="email" type="email" />
					<ServerErrors space={SPACES.FORM} />
					<SubmitContainer space={SPACES.FORM}>
						<BorderButton large green>
							RESET
						</BorderButton>
					</SubmitContainer>
				</div>
			</div>
			<Footer />
		</div>
	);
};

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
