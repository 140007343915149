import React from 'react';
import { useAppStore } from '@local/store';

interface Props {
	space: string;
}
type SubmitError = { message: string; title?: string }[];

const ServerErrors: React.FC<Props> = ({ space }) => {
	const { useStoreSpace } = useAppStore();
	const { submitError }: { submitError?: SubmitError } = useStoreSpace(space) ?? {};

	return (
		<div className="server-errors__container">
			{submitError?.map(({ message, title }, index) => (
				<div className="server-errors__content" key={index}>
					{message || title}
				</div>
			))}
		</div>
	);
};

ServerErrors.displayName = 'ServerErrors';

export default ServerErrors;
