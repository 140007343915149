import { ObjectSchema } from 'yup';

const catchFunc = (e) => {
	console.error(e);
	return null;
};

const API_ROOT = 'https://api.chainview.us/v1/api/';
const headersDefault = {
	Accept: 'application/problem+json, text/plain',
	'Content-Type': 'application/json;charset=UTF-8',
};

const request = async <D>(url, options, secure?: boolean, headers?: ObjectSchema): Promise<ApiResponse<D> | null> => {
	const tokenHeaders = secure ?? true ? JSON.parse(localStorage.getItem('t') ?? '{}') : {};
	const propsHeaders = headers || {};
	let resp;
	let blob;
	try {
		resp = await fetch(url, {
			mode: 'cors',
			headers: { ...headersDefault, ...tokenHeaders, ...propsHeaders },
			...options,
		});
		blob = await resp.text();
	} catch (e) {
		return catchFunc(e);
	}
	try {
		const json = JSON.parse(blob);
		return resp.ok ? { success: true, data: json } : { success: false, errors: [json] };
	} catch (e) {
		try {
			return resp.ok ? { success: true, data: blob } : { success: false, errors: [{ message: blob }] };
		} catch (e) {
			return catchFunc(e);
		}
	}
};

interface ApiResponse<D = unknown> {
	success?: boolean;
	headers?: ObjectSchema;
	data?: D;
	errors?: [{ message }];
}

// const referrerPolicy = 'no-referrer';
const apiGet = (resource: string): Promise<ApiResponse | null> => request(`${API_ROOT}${resource}`, { method: 'GET' });

const apiPost = <D>(
	resource: string,
	data: unknown,
	secure?: boolean,
	headers?: ObjectSchema
): Promise<ApiResponse<D> | null> =>
	request(`${API_ROOT}${resource}`, { method: 'POST', body: JSON.stringify(data) }, secure, headers);

export const api = {
	apiGet,
	apiPost,
};
