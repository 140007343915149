import React from 'react';
import './style.scss';

export const Spinner: React.FC = () => <div className="spinner" />;

export const Loader: React.FC = () => (
	<div className="loader">
		<Spinner /> LOADING...
	</div>
);

Loader.displayName = 'Loader';

export default Loader;
