import { useAppStore, routeActions } from '@local/store';
import React from 'react';
import { COMMON_SPACES, ROUTE_PATH_KEYS, BUTTON_VIEW, EMPTY, ENV } from '@local/constants';

import { Button } from '@local/components';
import 'pages/common/style.scss';

interface Props {
	link: string;
	space: string;
}

export const Pagination: React.FC<Props> = ({ link, space }) => {
	const { useStoreSpace, useAction } = useAppStore();

	const articles = useStoreSpace(space, 'value') ?? EMPTY.ARRAY;
	const value = Number(useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.PAGINATION)) || 1;
	const onChange = useAction(({ target: { value } }) => routeActions.push(link, { pagination: value }));

	const buttonProps = { value, onChange, view: BUTTON_VIEW.LINK };

	const nPages = Math.ceil((articles?.length || 1) / ENV.PAGES_LIMIT);

	return (
		<div className="pagination-container">
			{[...new Array(nPages)].map((_, i) => (
				<Button key={i} {...buttonProps} activeValue={i + 1}>
					{i + 1}
				</Button>
			))}
		</div>
	);
};
