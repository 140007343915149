import React from 'react';
import classname from 'classnames';
import { TErrors } from '../types';

export interface LabelProps {
	label?: string;
	className?: string;
	errors?: TErrors;
	disabled?: boolean;
}

export const Label: React.FC<LabelProps> = ({ disabled, errors, label, className = '', children }) => {
	const showErrors = Boolean(errors?.length);

	return (
		<label
			className={classname('Label', {
				'Label--error': showErrors,
				'Label--disabled': disabled,
				[className]: !!className,
			})}
		>
			{label && <span className="Label__text">{label}</span>}
			{children}
			{showErrors && (
				<div className="Label__errors">
					{Array.isArray(errors) && errors.map((error, i) => <div key={i}>{error.message}</div>)}
				</div>
			)}
		</label>
	);
};
