import { useAppStore, reducersCreators, actions } from '@local/store';
import { COMMON_SPACES, ROUTE_PATH_KEYS } from '@local/constants';
import SUB_PAGE_NAMES from 'pages/learn/SUB_PAGE_NAMES';
import { useEffectAsync } from '@local/hooks';
import { sortByDate } from '@local/services';
import SPACES from './SPACES';

const onchainLoader = async ({ get }) => sortByDate(await get('on-chains'));
const marketDataLoader = async ({ get }) => sortByDate(await get('market-data'));

const tagsReducer = reducersCreators.valueReducer([]);

const Actor = () => {
	const { useResource, useStoreSpace, useReducer, dispatch } = useAppStore();
	const subpage = useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.SUBPAGE);
	useResource(SPACES.LIST, subpage === SUB_PAGE_NAMES.ON_CHAIN ? onchainLoader : marketDataLoader);
	useReducer(SPACES.TAGS, tagsReducer);
	const listStore = useStoreSpace(SPACES.LIST);
	useEffectAsync(() => {
		const list = Array.isArray(listStore?.value) ? listStore.value : [];
		const arr = [];
		const set = new Set();
		list.forEach((article) =>
			article?.tags?.forEach(({ id, tag }) => {
				if (!set.has(id)) {
					arr.push({ id, tag });
					set.add(id);
				}
			})
		);
		dispatch(actions.set(SPACES.TAGS, arr));
	}, [listStore]);
	return null;
};

export default Actor;
