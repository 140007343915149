const BUTTON_VIEW = {
	LINK: 'link',
	UNDERLINED_LINK: 'underlined_link',
	GREEN_BUTTON: 'green_button',
	YELLOW_BUTTON: 'yellow_button',
	PUZZLE_GREEN: 'puzzle_green',
	BOX_GREEN: 'box_green',
	PUZZLE_DARK: 'puzzle_dark',
	SMALL_ICON: 'small_icon',
	BIG_ICON: 'big_icon',
};

export default BUTTON_VIEW;
