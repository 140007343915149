import { routeActions, useAppStore } from '@local/store';
import * as yup from 'yup';
import { LINKS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import SPACES from './SPACES';

const schema = yup.object().shape({
	email: yup.string().required(),
});

const Actor = () => {
	const { useForm, dispatch } = useAppStore();
	useForm({
		space: SPACES.FORM,
		schema,
		submitter: ({ apiPost }, value) => apiPost('ResetPassword', value),
		onSaved: () => {
			dispatch(routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.VERIFICATION_CODE }));
		},
	});
	return null;
};

export default Actor;
