const ROUTE_PATH_KEYS = {
	PAGE: 'page',
	SUBPAGE: 'subpage',
	TAG: 'tag',
	PAGE_VIEW: 'pageview',
	ID: 'id',
	PAGINATION: 'pagination',
};

export default ROUTE_PATH_KEYS;
