import * as actionTypes from './actionTypes';

export const startLoad = (space, reqId) => ({ space, type: actionTypes.START_LOAD, reqId });

export const loadSuccess = (space, payload, reqId) => ({
	space,
	type: actionTypes.LOAD_SUCCESS,
	payload,
	reqId,
});

export const loadError = (space, error, reqId) => ({ space, type: actionTypes.LOAD_ERROR, error, reqId });

export const submit = (space, payload) => ({ space, type: actionTypes.SUBMIT, payload });

export const submitSuccess = (space, payload) => ({
	space,
	type: actionTypes.SUBMIT_SUCCESS,
	payload,
});

export const submitError = (space, payload) => ({
	space,
	type: actionTypes.SUBMIT_ERROR,
	payload,
});

export const changeFilter = (space, payload) => ({
	space,
	type: actionTypes.CHANGE_FILTER,
	payload,
});

export const setFilter = (space, payload) => ({
	space,
	type: actionTypes.SET_FILTER,
	payload,
});

export const changeSort = (space, payload) => ({
	space,
	type: actionTypes.CHANGE_SORT,
	payload,
});

export const localSort = (space, payload) => ({
	space,
	type: actionTypes.LOCAL_SORT,
	payload,
});

export const changePage = (space, payload) => ({
	space,
	type: actionTypes.CHANGE_PAGE,
	payload,
});

export const setNeedReload = (space, payload) => ({
	space,
	type: actionTypes.SET_NEED_RELOAD,
	payload,
});

export const set = (space, payload) => ({
	space,
	type: actionTypes.SET,
	payload,
});

export const setPath = (space, path, value) => ({
	space,
	type: actionTypes.SET_PATH,
	payload: { path, value },
});

export const merge = (space, payload) => ({
	space,
	type: actionTypes.MERGE,
	payload,
});

export const validationErrors = (space, payload) => ({
	space,
	type: actionTypes.VALIDATION_ERRORS,
	payload,
});

export const invalidate = (space) => ({
	space,
	type: actionTypes.INVALIDATE,
});

export const reset = (space) => ({ space, type: actionTypes.RESET });

export const del = (space, payload) => ({
	space,
	type: actionTypes.DEL,
	payload,
});

export const setMain = (space, payload) => ({
	space,
	type: actionTypes.SET_MAIN,
	payload,
});

export const select = (space, payload) => ({
	space,
	type: actionTypes.SELECT,
	payload,
});

export const push = (space, payload) => ({ space, type: actionTypes.PUSH, payload });

export const shift = (space) => ({ space, type: actionTypes.SHIFT });
