import React, { useCallback } from 'react';
import { InterectiveProps } from '@local/components/types';

import './style.scss';

const uniqueKey = '@@_custom-switch_@@';

type Props = InterectiveProps<boolean>;

const Switch: React.FC<Props> = ({ value, onChange, name }) => {
	const onChangeHandler = useCallback(
		({ target: { name, checked } }: React.ChangeEvent<HTMLInputElement>) => {
			onChange && onChange({ target: { name, value: checked } });
		},
		[onChange]
	);
	return (
		<div className="switch-container">
			<input type="checkbox" id={uniqueKey} checked={Boolean(value)} onChange={onChangeHandler} name={name} />
			<label htmlFor={uniqueKey} />
		</div>
	);
};

export default Switch;
