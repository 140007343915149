import React, { useCallback } from 'react';
import { BUTTON_VIEW, ENV } from '@local/constants';
import { Button, InterectiveProps } from '@local/components';
import { ArticleItem } from '@local/dataTypes';

import './style.scss';

const ListItem: React.FC<InterectiveProps<ArticleItem>> = ({ value, name, onChange }) => {
	const onClickHandler = useCallback(() => {
		typeof onChange === 'function' && onChange({ target: { name, value } });
	}, [name, value]);

	return (
		<div className="learn-list__item" aria-hidden="true" onClick={onClickHandler}>
			<img className="img" src={`${ENV.API_ROOT}${value?.thumb?.[0]?.formats?.thumbnail?.url}`} alt="img" />
			<div className="description-container">
				<div className="title">{value?.title}</div>
				<div className="description">{value?.description}</div>
				<div className="button-set">
					{value?.tags?.map(({ id, tag }) => (
						<Button key={id} view={BUTTON_VIEW.BOX_GREEN} activeValue={id}>
							{tag.toUpperCase()}
						</Button>
					))}
				</div>
			</div>
		</div>
	);
};

export default ListItem;
