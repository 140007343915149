import React, { useMemo } from 'react';
import { useAppStore, routeActions } from '@local/store';
import { COMMON_SPACES, EMPTY, ENV, LINKS, ROUTE_PATH_KEYS } from '@local/constants';
import ArticleListItem from './ArticleListItem';

interface Props {
	space: string;
	pageLimit?: number;
	bgInfo?: boolean;
	withoutFirst?: boolean;
}

const ArticlesList: React.FC<Props> = ({
	space,
	pageLimit = ENV.PAGES_LIMIT,
	bgInfo = false,
	withoutFirst = false,
}) => {
	const { useStoreSpace, useAction } = useAppStore();
	const { value, loaded } = useStoreSpace(space) ?? {};
	const onChange = useAction(({ target: { value } }) => routeActions.push(LINKS.NEWS, { id: value?.id }));

	const page = Math.max(Number(useStoreSpace(COMMON_SPACES.PATH_PARAMS, ROUTE_PATH_KEYS.PAGINATION)) || 1, 1);

	const list = useMemo(() => {
		const listArray = Array.isArray(value) ? value : EMPTY.ARRAY;
		return listArray.slice((page - 1) * pageLimit, page * pageLimit);
	}, [value, page, pageLimit]);

	return (
		<div className="article-list">
			<section className="article-list__columns">
				{(loaded &&
					list.map((article, i) =>
						withoutFirst && i === 0 ? null : (
							<ArticleListItem key={i} bgInfo={bgInfo} value={article} onChange={onChange} />
						)
					)) ??
					null}
			</section>
		</div>
	);
};

export default ArticlesList;
