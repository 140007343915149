import './App.css';
import React from 'react';
import AppActor from './AppActor';
import { AuthActor } from './AuthActor';
import RouterContainer from './route/RouterContainer';

const App = () => (
	<>
		<AppActor />
		<AuthActor />
		<RouterContainer />
	</>
);

export default App;
