import React, { useCallback } from 'react';
import { actions, useAppStore } from '@local/store';
import { Button } from '@local/components';
import { BUTTON_VIEW } from '@local/constants';
import classNames from 'classnames';
import SPACES from '../SPACES';
import bin from '../../../images/bin.svg';

import './style.scss';

type Device = {
	sessionId: string;
	type: string;
	lastLogin: string;
	ip: string;
	userAgent: string;
};

type Devices = {
	value: readonly Device[] | undefined;
};

const Header = (
	<div className="table-row header">
		<div className="table-cell">Device</div>
		<div className="table-cell">Last use</div>
		<div className="table-cell">Status</div>
		<div className="table-cell" />
	</div>
);

const Row: React.VFC<Device & { onDelete: (p: { target: { value: string } }) => void }> = ({
	ip,
	type,
	lastLogin,
	sessionId,
	userAgent,
	onDelete,
}) => {
	const DateInstance = new Date(lastLogin);
	const CurrentDateInstance = new Date();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [day, month, date, year] = DateInstance?.toDateString()?.split(' ') ?? [];
	const [hour, minutes] = DateInstance?.toTimeString()?.split(':') ?? [];
	const showTime =
		DateInstance.getDate() === CurrentDateInstance.getDate() &&
		DateInstance.getMonth() === CurrentDateInstance.getMonth() &&
		DateInstance.getFullYear() === CurrentDateInstance.getFullYear()
			? `${hour}:${minutes}`
			: `${date} ${month}`;

	return (
		<div className={classNames('table-row', { active: false })} key={sessionId}>
			<div className="table-cell cell-1">
				<div className="device">{userAgent}</div>
				<div className="location">
					{type} - {ip}
				</div>
			</div>
			<div className="table-cell cell-2">{showTime}</div>
			<div className="table-cell cell-3">Keep alive</div>
			<div
				aria-hidden="true"
				className="table-cell cell-4"
				onClick={useCallback(() => {
					onDelete && onDelete({ target: { value: sessionId } });
				}, [onDelete, sessionId])}
			>
				<img src={bin} alt="" />
			</div>
		</div>
	);
};

const Security: React.FC = () => {
	const { useStoreSpace, useAction } = useAppStore();
	const store: Devices = useStoreSpace(SPACES.SESSIONS);
	const onDelete = useAction(({ target: { value } }) => actions.submit(SPACES.SESSIONS, value));
	const list = store?.value;

	return (
		<div className="security-container">
			<h1 className="title">Connected Devices</h1>
			<div className="table">
				{Header}
				{list?.map((props) => (
					<Row {...props} key={props.sessionId} onDelete={onDelete} />
				))}
			</div>
			<Button
				className="terminate-sessions"
				view={BUTTON_VIEW.UNDERLINED_LINK}
				onChange={useAction(() => actions.submit(SPACES.SESSIONS))}
			>
				Terminate all other sessions
			</Button>
		</div>
	);
};

Security.displayName = 'Security';

export default Security;
