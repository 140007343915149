const SPACES = {
	SETTINGS: 'settings',
	REFERRAL_INFO: 'referral_info',
	REFERRAL_LINK: 'referral_link',
	SESSIONS: 'sessions',
	REDIRECT: 'redirect',
	INFO: 'info',
	BILLING_PORTAL: 'billing_portal',
};

export default SPACES;
