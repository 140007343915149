import React from 'react';
import moment from 'moment';
import { InputContainer, SubmitContainer } from '@local/containers';
import { BorderButton, Button } from '@local/components';
import { routeActions, useAppStore } from '@local/store';
import { BUTTON_VIEW, LINKS, PLANS } from '@local/constants';
import PAGE_NAMES from 'pages/PAGE_NAMES';
import SPACES from '../SPACES';

import './refSystem.scss';

export const PLANS_ACTIVITY = {
	0: 'REGISTERED',
	1: 'PRO',
	2: 'PRO+',
	3: 'PREMIUM',
};

interface REFERRAL {
	value?: {
		tokens?: number;
		nv?: number;
		nu?: number;
		name?: 'string';
		activities?: 'string';
		referralLink?: 'string';
	};
}

const RefSystem: React.FC = () => {
	const { useStoreSpace, dispatch } = useAppStore();
	const { value: referralInfo }: REFERRAL = useStoreSpace(SPACES.REFERRAL_INFO) ?? {};
	const { value: userInfo } = useStoreSpace(SPACES.INFO) ?? {};

	if (userInfo?.plan === PLANS.FREE)
		return (
			<article className="referral-system">
				<h1 className="title">Referral program</h1>
				<div className="active-content">
					You must have an
					<span
						tabIndex={-1}
						role="button"
						onClick={() => dispatch(routeActions.push(LINKS.PAGE, { page: PAGE_NAMES.ACCOUNT_SETTINGS_UPGRADE }))}
					>
						active plan
					</span>
					to be able to use the referral program.
				</div>
				<p className="footer-description">
					Receive 28% on every user who pays with your referral. Referral program rules at
					<span>referraltos.chainview.us</span>
				</p>
			</article>
		);

	if (!referralInfo?.tokens)
		return (
			<article className="referral-system">
				<h1 className="title">Referral program</h1>
				<InputContainer label="Referral Name" name="name" space={SPACES.REFERRAL_INFO} />
				<SubmitContainer space={SPACES.REFERRAL_INFO}>
					<Button className="set-referral">
						<BorderButton green>SET REFERRAL</BorderButton>
					</Button>
				</SubmitContainer>
			</article>
		);

	const referralLink = referralInfo?.referralLink;

	const activityData = referralInfo?.activities?.split(';')?.map((e) => e.split(':'));

	const copyRefLink = referralLink || `https://chainview.us/home/ref_${referralInfo?.name}`;

	const copyReferralLink = () => {
		const el = document.createElement('input');
		el.value = copyRefLink;
		el.id = 'url-input';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		el.remove();
	};

	return (
		<article className="referral-system">
			<h1 className="title">Referral program</h1>
			<div className="ref-form">
				<div className="input-container">
					<InputContainer label="Referral Name" name="name" space={SPACES.REFERRAL_INFO} />
					<SubmitContainer space={SPACES.REFERRAL_INFO}>
						<Button view={BUTTON_VIEW.UNDERLINED_LINK}>Change referral name</Button>
					</SubmitContainer>
				</div>
				<div className="ref-data">
					<div className="tokens">{`Tokens: ${referralInfo?.tokens}`}</div>
					<div>{`Visitators: ${referralInfo?.nv}`}</div>
					<div>{`Registered Users: ${referralInfo?.nu}`}</div>
				</div>
			</div>
			<div className="ref-link">
				<p>Referral Link</p>
				<SubmitContainer space={SPACES.REFERRAL_INFO}>
					<div tabIndex={-1} role="button" onClick={copyReferralLink}>
						{referralInfo?.referralLink || `https://chainview.us/home/ref_${referralInfo?.name}`}
					</div>
				</SubmitContainer>
			</div>
			<div className="refs">
				<h1 className="table-title">Last Activites</h1>
				{referralInfo?.activities?.length ? (
					<div className="table">
						<div className="table-header">
							<div>Date</div>
							<div>Email</div>
							<div>Activity</div>
						</div>
						<div className="table-body">
							{activityData?.map(([date, email, plan], key) => (
								<div className="table-row" key={key}>
									<div className="table-cellDate">{moment(+date).format('D MMMM YYYY HH:mm:ss')}</div>
									<div className="table-cellEmail">{email}</div>
									<div className="table-cellStatus">{PLANS_ACTIVITY[plan]}</div>
								</div>
							))}
						</div>
					</div>
				) : (
					''
				)}
			</div>
			<p className="footer-description">
				Receive 28% on every user who pays with your referral. Referral program rules at
				<span>referraltos.chainview.us</span>
			</p>
		</article>
	);
};

export default RefSystem;
